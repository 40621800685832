import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import UserSetting from "../../UserSettings.json";
import Loader from "../../Components/Common/Loader ";
import VideoMasterComponent from "../VideoMaster/VideoMasterComponent";
import { convertDateFormat } from "../../Utils/commonFunction";
import { Edit, Delete } from "../../Components/Common/EditDelete";

export default function VideoMaster() {
    const [VideoMasterData, setVideoMasterData] = useState([]);
    const [editVideoMasterData, setEditVideoMasterData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showVideoMasterModal, setShowVideoMasterModal] = useState(false);
  
    useEffect(() => {
      getVideoMaster();
    }, []);
  
    // Fetch all Areas
    const getVideoMaster = () => {
      setIsLoading(true);
      api
        .get("VideoMaster/AllVideoMasters")
        .then((result) => {
          setVideoMasterData(result.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  
    const getSingleCompony = (id) => {
      ;
      api
        .get(`VideoMaster/SingleVideoMaster?VideoMasterID=${id}`)
        .then((result) => {
          const VideoMasterData = result.data.data;
          setEditVideoMasterData(VideoMasterData);
          setShowVideoMasterModal(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    // Delete Area
    const DeleteVideoMaster = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to Delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .patch(`VideoMaster/DeleteVideoMaster?VideoMasterID=${id}`)
            .then((result) => {
              if (result.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: "Your record has been deleted.",
                  showConfirmButton: false,
                  timer: 1500,
                });
                getVideoMaster();
              }
            })
            .catch((error) => {
              console.log(error);
              Swal.fire("Error", "An error occurred.", "error");
            });
        }
      });
    };
  
    const columnDefs = [
      {
        headerName: "VideoMaster Name",
        field: "videoMasterName",
        headerClass: "widthTableColum",
        sortable: true,
        filter: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        wrapText: true,
        autoHeight: true,
        flex: 1,
        minWidth: 50,
      },
      {
        headerName: "DisplayPriority",
        field: "displayPriority",
        headerClass: "widthTableColum",
        sortable: true,
        filter: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        wrapText: true,
        autoHeight: true,
        flex: 1,
        minWidth: 50,
      },
      
      {
        headerName: "edit",
        width: 70,
        field: "videoMasterID",
        cellStyle: { textAlign: "center" },
        headerClass: "center-text widthTableColum",
        cellRenderer: ({ value }) => (
          <button onClick={() => getSingleCompony(value)}>
            <Edit />
          </button>
        ),
        pinned: "right",
      },
      {
        headerName: "Delete",
        width: 90,
        field: "videoMasterID",
        cellStyle: { textAlign: "center" },
        headerClass: "center-text widthTableColum",
        cellRenderer: ({ value }) => (
          <button onClick={() => DeleteVideoMaster(value)}>
            <Delete />
          </button>
        ),
        pinned: "right",
      },
    ];
  
    const HandlModelClose = () => {
      setShowVideoMasterModal(false);
      setEditVideoMasterData(null);
    };
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card.Header className="d-flex justify-content-between">
        <span>VideoMaster</span>
        <Button
          className="btn-tapti"
          onClick={() => setShowVideoMasterModal(true)}
        >
          Add VideoMaster
        </Button>{" "}
      </Card.Header>
      <Card.Body className="withBorder">
        <AgGridComponent
          rowData={VideoMasterData}
          columnDefs={columnDefs}
          paginationPageSize={15}
        />
      </Card.Body>

      <GlobalModal
        title="New VideoMaster"
        bodyComponent={
          <VideoMasterComponent
            onClose={HandlModelClose}
            returnID={getVideoMaster}
            editedVideoMasterData={editVideoMasterData}
            clearData={showVideoMasterModal}
          />
        }
        isOpen={showVideoMasterModal}
        onClose={HandlModelClose}
      />
    </>
  )
}
