import React from 'react'
import { Form } from 'react-bootstrap'

export default function GlobalFormTagWithSubmit({ validated, onSubmit, refName, children ,formName}) {
  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={(e) => {
        if (e) {
          e.preventDefault();
        }

        if (refName.current && e) {
          if (e.target.name === formName) {
            onSubmit(e);
          }
        }
      }}
      ref={refName}
      name={formName}
    >
      {children}
    </Form>
  )
}

// How to use
// const MyComponent = () => {
  // const [validated, setValidated] = useState(false);
  // const EventFormRef = useRef(null);
//   return (
  // <GlobalFormTagWithSubmit
  //       validated={validated}
  //       onSubmit={insertEventMaster}
  //       refName={EventFormRef}
  //       formName="EventForm"
  //     >
  //       Data
  //     </GlobalFormTagWithSubmit>
//   );
// };

// export default MyComponent;
