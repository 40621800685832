import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button, Image } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserSetting from "../../UserSettings.json";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const emptyCMS = {
  CMS_id: 0,
  title: "",
  metakeywords: "",
  metaDescription: "",
};

export default function CMSComponent({
  CMSID,
  editedCMSData,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyCMS);
  const CMSFormRef = useRef(null);
  const [details, setDetails] = useState("");

  useEffect(() => {
    if (clearData == false) {
      setFormData(emptyCMS);
      setDetails("")
    }
  }, [clearData]);

  useEffect(() => {
    ;
    if (editedCMSData) {
      setFormData({
        CMS_id: editedCMSData.cmsid,
        title: editedCMSData.title,
        metakeywords: editedCMSData.metakeywords,
        metaDescription: editedCMSData.metaDescription,
      });
      setDetails(editedCMSData.details);
    }
  }, [editedCMSData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Insert & Update Data
  const insertCMSMaster = (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    } else {
      const data = {
        cMSID: formData?.CMS_id || 0,
        Title: formData.title,
        Details: details,
        Metakeywords: formData.metakeywords,
        MetaDescription: formData.metaDescription,
      };

      const endpoint = formData.CMS_id === 0 ? "CMS/InsertCMS" : "CMS/EditCMS";

      api[formData.CMS_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          if (result.status === 200) {
            setValidated(false)
            toast.success(result.data.message);
            if (CMSID) {
              CMSID(result.data.data);
            }
            CMSID(result.data.data);
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          setFormData(emptyCMS);
          setDetails("")
          onClose();
          
        });
    }
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (CMSFormRef.current && e) {
            if (e.target.name === "CMSForm") {
              insertCMSMaster(e);
            }
          }
        }}
        ref={CMSFormRef}
        name="CMSForm"
      >
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="CMS_name">
              <Form.Label>CMS Title:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Enter CMS Name"
                  value={formData.title}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter CMS title.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="HeaderField">
              <Form.Label>Detail:</Form.Label>
              <InputGroup className="mb-3">
                <div style={{ height: "400px", width: "100%" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={details}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDetails(data);
                    }}
                  />
                </div>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="CMS_name">
              <Form.Label>Meta keywords:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="metakeywords"
                  placeholder="Enter meta keywords"
                  value={formData.metakeywords}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Meta keywords.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="CMS_name">
              <Form.Label>Meta Description:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="metaDescription"
                  placeholder="Enter meta Description"
                  value={formData.metaDescription}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Meta Description
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="  margin_top10 items-end flex justify-end">
          <Button
            type="submit"
            variant="primary"
            className="mb-20px btn-tapti "
          >
            {formData?.CMS_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
