import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import UserSetting from "../../UserSettings.json";
import Loader from "../../Components/Common/Loader ";
import VideosComponent from "../Videos/VideosComponent";
import { convertDateFormat } from "../../Utils/commonFunction";
import { Edit, Delete } from "../../Components/Common/EditDelete";

export default function Videos() {
    const [VideosData, setVideosData] = useState([]);
    const [editVideosData, setEditVideosData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showVideosModal, setShowVideosModal] = useState(false);
  
    useEffect(() => {
      getVideos();
    }, []);
  
    // Fetch all Areas
    const getVideos = () => {
      setIsLoading(true);
      api
        .get("Video/AllVideos")
        .then((result) => {
          setVideosData(result.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  
    const getSingleCompony = (id) => {
      ;
      api
        .get(`Video/SingleVideo?VideoID=${id}`)
        .then((result) => {
          const VideosData = result.data.data;
          setEditVideosData(VideosData);
          setShowVideosModal(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    // Delete Area
    const DeleteVideos = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to Delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .patch(`Video/DeleteVideo?VideoID=${id}`)
            .then((result) => {
              if (result.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: "Your record has been deleted.",
                  showConfirmButton: false,
                  timer: 1500,
                });
                getVideos();
              }
            })
            .catch((error) => {
              console.log(error);
              Swal.fire("Error", "An error occurred.", "error");
            });
        }
      });
    };
  
    const columnDefs = [
      {
        headerName: "Videos Name",
        field: "videoName",
        headerClass: "widthTableColum",
        sortable: true,
        filter: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        wrapText: true,
        autoHeight: true,
        flex: 1,
        minWidth: 50,
      },
      {
        headerName: "Video MasterName",
        field: "videoMasterName",
        headerClass: "widthTableColum",
        sortable: true,
        filter: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        wrapText: true,
        autoHeight: true,
        flex: 1,
        minWidth: 50,
      },
      {
        headerName: "URL",
        field: "url",
        headerClass: "widthTableColum",
        sortable: true,
        filter: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        wrapText: true,
        autoHeight: true,
        flex: 1,
        minWidth: 700,
        cellRenderer: (params) => {
            return <div dangerouslySetInnerHTML={{ __html: params.value }} />;
          },
      },
      
      {
        headerName: "edit",
        width: 70,
        field: "videoID",
        cellStyle: { textAlign: "center" },
        headerClass: "center-text widthTableColum",
        cellRenderer: ({ value }) => (
          <button onClick={() => getSingleCompony(value)}>
            <Edit />
          </button>
        ),
        pinned: "right",
      },
      {
        headerName: "Delete",
        width: 90,
        field: "videoID",
        cellStyle: { textAlign: "center" },
        headerClass: "center-text widthTableColum",
        cellRenderer: ({ value }) => (
          <button onClick={() => DeleteVideos(value)}>
            <Delete />
          </button>
        ),
        pinned: "right",
      },
    ];
  
    const HandlModelClose = () => {
      setShowVideosModal(false);
      setEditVideosData(null);
    };
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card.Header className="d-flex justify-content-between">
        <span>Videos</span>
        <Button
          className="btn-tapti"
          onClick={() => setShowVideosModal(true)}
        >
          Add Videos
        </Button>{" "}
      </Card.Header>
      <Card.Body className="withBorder">
        <AgGridComponent
          rowData={VideosData}
          columnDefs={columnDefs}
          paginationPageSize={15}
        />
      </Card.Body>

      <GlobalModal
        title="New Videos"
        bodyComponent={
          <VideosComponent
            onClose={HandlModelClose}
            returnID={getVideos}
            editedVideosData={editVideosData}
            clearData={showVideosModal}
          />
        }
        isOpen={showVideosModal}
        onClose={HandlModelClose}
      />
    </>
  )
}
