import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Inquiry from "../../ImageForSidebar/icons8-inquiry-94.png";
import api from "../../Utils/api";
import "./DashBoard.css";

import { InputGroupWithOutIconAndUpperLabel } from "../../Components/Common/MyComponent";

export default function DashBoard() {
  const [inquiryData, setInquiryData] = useState([]);
  const [inquiryCount, setInquiryCount] = useState(0);
  const [activeButton, setActiveButton] = useState("All"); // State to track active button

  useEffect(() => {
    getInquiry();
  }, []);

  // Fetch all Inquiries
  const getInquiry = () => {
    api
      .get("Inquiry/AllInquiries")
      .then((result) => {
        setInquiryData(result.data.data);
        setInquiryCount(result.data.data.length);
        setActiveButton("All"); // Set active button to 'all'
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fetch Seen and Unseen Inquiries
  const getSeenAndUnseenInquiries = (seen) => {
    api
      .get(`Inquiry/SeenAndUnseenInquiries?seen=${seen}`)
      .then((result) => {
        setInquiryData(result.data.data);
        setInquiryCount(result.data.data.length);
        setActiveButton(seen ? "Seen" : "Unseen"); 
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [data, setData] = useState([
    { id: 1, firstName: "John", lastName: "Doe", username: "@johnny" },
    { id: 2, firstName: "Jane", lastName: "Doe", username: "@jane" },
    { id: 3, firstName: "Jim", lastName: "Smith", username: "@jimmy" },
  ]);

  const handleFirstNameChange = (e, rowIndex, field) => {
    console.log(`First Name of row ${rowIndex} changed to:`, e.target.value);
    // Update the data state accordingly
  };

  const handleLastNameChange = (e, rowIndex, field) => {
    console.log(`Last Name of row ${rowIndex} changed to:`, e.target.value);
    // Update the data state accordingly
  };

  const handleUsernameChange = (e, rowIndex, field) => {
    console.log(`Username of row ${rowIndex} changed to:`, e.target.value);
    // Update the data state accordingly
  };

  const columns = [
    {
      header: "First Name",
      field: "firstName",
      onChange: handleFirstNameChange,
    },
    { header: "Last Name", field: "lastName", onChange: handleLastNameChange },
    { header: "Username", field: "username", onChange: handleUsernameChange },
  ];

  return (
    <>
      <Card.Header className="d-flex justify-content-between">
        <span>DashBoard</span>
      </Card.Header>
      <Card.Body className="">
        <Row>
          <Col md={3}>
            <div className=" bg-white rounded-3xl border-t-8 border-green-400 flex flex-col justify-around shadow-md items-center ">
              <img src={Inquiry} alt="" />
              <p className="text-lg font-bold font-sans">
                {" "}
                {activeButton} Inquiry
              </p>
              <div className="py-3">
                <h1 className="text-gray-400 text-5xl">{inquiryCount}</h1>
              </div>
              <div className="flex justify-between">
                <div className="text-sm flex gap-2">
                  {activeButton != "All" && (
                    <button className="btn-tapti" onClick={() => getInquiry()}>
                      All
                    </button>
                  )}
                  {activeButton != "Seen" && (
                    <button
                      className="btn-tapti"
                      onClick={() => getSeenAndUnseenInquiries(true)}
                    >
                      Seen
                    </button>
                  )}
                  {activeButton != "Unseen" && (
                    <button
                      className="btn-tapti" 
                      onClick={() => getSeenAndUnseenInquiries(false)}
                    >
                      Unseen
                    </button>
                  )}
                </div>
              </div>
              <div
                className="m-3 bg-neutral-200 w-5/6  rounded-full"
                style={{ fontSize: "14px" }}
              >
                <a href="/Inquiry">
                  More info <i className="fa fa-arrow-circle-right"></i>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </>
  );
}
