import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button, Image } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserSetting from "../../UserSettings.json";
import InputGroupWithIcon from "../../Components/Common/InputGroupWithIcon ";
import CKEditorInput from "../../Components/Common/CKEditorInput";
import SubmitButtonOfForm from "../../Components/Common/SubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Components/Common/GlobalFormTagWithSubmit";
import GlobalDDComponent from "../../Components/Common/GlobalDDComponent";
import EventComponent from "../Event/EventComponent";
import ImageAddAndPreview from "../../Components/Common/ImageAddAndPreview";

const emptyGallery = {
  Image_ID: 0,
  EventID: 0,
};

export default function GalleryComponent({
  returnID,
  editedGalleryData,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyGallery);
  const GalleryFormRef = useRef(null);
  const imageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [ddValidationError, setDDValidationError] = useState(false);

  useEffect(() => {
    if (editedGalleryData) {
      setFormData({
        Image_ID: editedGalleryData.imageID,
        EventID: editedGalleryData.eventID,
      });
      setImagePreview(UserSetting.imgURL + editedGalleryData.imagePath);
    }
  }, [editedGalleryData]);

  useEffect(() => {
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Insert & Update Data
  const insertGalleryMaster = (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    } else {
      const form_Data = new FormData();
      form_Data.append("ImageID", formData?.Image_ID || 0);
      form_Data.append("EventID", formData.EventID);
      form_Data.append("image", selectedImage);

      const endpoint =
        formData.Image_ID === 0 ? "Gallery/InsertImage" : "Gallery/EditImage";

      api[formData.Image_ID === 0 ? "post" : "patch"](endpoint, form_Data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            if (returnID) {
              returnID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset = () => {
    setSelectedImage(null);
    setImagePreview(null);
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
    setFormData(emptyGallery);
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
    // Preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        onSubmit={insertGalleryMaster}
        refName={GalleryFormRef}
        formName="GalleryForm"
      >
        <Row>
          <GlobalDDComponent
            ColSize={8}
            formLabel="Event"
            apiEndpoint="Event/AllEvents"
            valueOfDD="eventID"
            labelOfDD="eventName"
            onSelectOption={(eventID) => {
              setFormData((prevData) => ({
                ...prevData,
                EventID: eventID,
              }));
              setDDValidationError(false);
            }}
            selectedOptionOfComponent={formData.EventID}
            ValidationError={ddValidationError}
            offcanvasTitle="Add Event"
            offcanvasBodyComponent={<EventComponent />}
          />
        </Row>
        <Row>
          <ImageAddAndPreview
            imagePreview={imagePreview}
            onChange={handleImageChange}
            imageInputRef={imageInputRef}
            Label = "Image"
          />
        </Row>
        <SubmitButtonOfForm HeadID={formData?.Image_ID} />
      </GlobalFormTagWithSubmit>
    </>
  );
}
