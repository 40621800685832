import React, { useState, useEffect } from "react";
import { Card, Button, Image,Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import ClientLogoComponent from "./ClientLogoComponent";
import UserSetting from "../../UserSettings.json";
import Loader from "../../Components/Common/Loader ";
import { Edit, Delete } from "../../Components/Common/EditDelete";

export default function ClientLogo() {
  const [ClientLogoData, setClientLogoData] = useState([]);
  const [editClientLogoData, setEditClientLogoData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showClientLogoModal, setShowClientLogoModal] = useState(false);
  // const [isMultiAdd, setIsMultiAdd] = useState(false);

  useEffect(() => {
    getClientLogo();
  }, []);

  // Fetch all Areas
  const getClientLogo = () => {
    setIsLoading(true);
    api
      .get("MediclaimImage/AllMediclaimImage")
      .then((result) => {
        setClientLogoData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSingleCompony = (id) => {
    api
      .get(`MediclaimImage/SingleMediclaimImage?MediclaimImageID=${id}`)
      .then((result) => {
        const ClientLogoData = result.data.data;
        setEditClientLogoData(ClientLogoData);
        setShowClientLogoModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const DeleteClientLogo = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`MediclaimImage/DeleteMediclaimImage?MediclaimImageID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getClientLogo();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const columnDefs = [
    {
      headerName: "DisplayPriority",
      field: "displayPriority",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 50,
    },
    {
      headerName: "Client Logo",
      flex: 1,
      minWidth: 150,
      field: "mediclaimImagePath",
      autoHeight: true,
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum ",
      cellRenderer: ({ value }) => (
        <div className="p-2.5">
          <Image
            style={{ width: "50%", height: "50%" }}
            src={UserSetting.imgURL + value}
          ></Image>
        </div>
      ),
    },
    {
      headerName: "Edit",
      width: 70,
      field: "mediclaimImageID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button onClick={() => getSingleCompony(value)}>
          <Edit />
        </button>
      ),
      pinned: "right",
    },
    {
      headerName: "Delete",
      width: 90,
      field: "mediclaimImageID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button onClick={() => DeleteClientLogo(value)}>
          <Delete />
        </button>
      ),
      pinned: "right",
    },
  ];
  const HandlModelClose = () => {
    setShowClientLogoModal(false);
    setEditClientLogoData(null);
  };
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card.Header className="d-flex justify-content-between">
        <span>Client Logo</span>
        <Button
          className="btn-tapti"
          onClick={() => setShowClientLogoModal(true)}
        >
          Add ClientLogo
        </Button>{" "}
      </Card.Header>
      <Card.Body className="withBorder">
        <AgGridComponent
          rowData={ClientLogoData}
          columnDefs={columnDefs}
          paginationPageSize={4}
        />
      </Card.Body>

      <GlobalModal
        title="New ClientLogo"
        bodyComponent={
          <ClientLogoComponent
            onClose={HandlModelClose}
            ClientLogoID={getClientLogo}
            editedClientLogoData={editClientLogoData}
            clearData={showClientLogoModal}
          />
        }
        isOpen={showClientLogoModal}
        onClose={HandlModelClose}
      />
    </>
  );
}
