import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button ,Image} from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserSetting from "../../UserSettings.json";

const emptyMasterMenu = {
    MasterMenu_id: 0,
    MasterMenu_name: "",
    ControllerName: "",
    ActionName: "",
    FriendlyUrl: "",
    DisplayPriority: "",
    IsActive: true,
  };

export default function MasterMenuComponent({
    MasterMenuID,
    returnID,
    editedMasterMenuData,
    clearData,
    onClose,
  }) {

    const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyMasterMenu);
  const MasterMenuFormRef = useRef(null);
 
  useEffect(() => {
    
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

    useEffect(() => {
      
      if (editedMasterMenuData) {
        setFormData({
          MasterMenu_id: editedMasterMenuData.masterMenuID,
          MasterMenu_name: editedMasterMenuData.masterMenuName,
        ControllerName : editedMasterMenuData.controllerName,
        ActionName : editedMasterMenuData.actionName,
        FriendlyUrl : editedMasterMenuData.friendlyUrl,
        DisplayPriority : editedMasterMenuData.displayPriority,
        IsActive : editedMasterMenuData.isActive,
        });
      }
    }, [editedMasterMenuData]);

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const inputValue = type === "checkbox" ? checked : value;
        setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
      };

  // Insert & Update Data
  const insertMasterMenuMaster = (event) => {
    
    // setValidated(true);
    // event.preventDefault();
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.stopPropagation();
    //   return;
      
    // }
      setValidated(true);
    const form = MasterMenuFormRef.current;
    if (!form.checkValidity()) {
      return;
    } else {
      
        const data = {
            MasterMenuID : formData?.MasterMenu_id || 0 ,
            MasterMenuName: formData.MasterMenu_name,
            ControllerName : formData.ControllerName,
            ActionName : formData.ActionName,
            FriendlyUrl : formData.FriendlyUrl,
            DisplayPriority : formData.DisplayPriority,
            IsActive : formData.IsActive
        }

      const endpoint =
        formData.MasterMenu_id === 0
          ? "MasterMenu/InsertMasterMenu"
          : "MasterMenu/EditMasterMenu";

      api[formData.MasterMenu_id === 0 ? "post" : "patch"](endpoint, data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            if (MasterMenuID) {
              MasterMenuID(result.data.data);
            }
            if(returnID){
              returnID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset =()=>{
    setFormData(emptyMasterMenu);
  }


  return (
    <>
    <Form
      noValidate
      validated={validated}
      // onSubmit={(event) => insertMasterMenuMaster(event, { resetForm })}
      // onSubmit={(e) => {
      //   if (e) {
      //     e.preventDefault();
      //   }

      //   if (MasterMenuFormRef.current && e) {
      //     // Check for the formName to call insertCity
      //     if (e.target.name === "MasterMenuForm") {
      //       insertMasterMenuMaster(e);
      //     }
      //   }
      // }}
      ref={MasterMenuFormRef}
      name="MasterMenuForm"
    >
      <Row>
        <Col md={8} sm={8} lg={8}>
          <Form.Group controlId="MasterMenu_name">
            <Form.Label>MasterMenu Name:</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i className="fas fa-building"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="MasterMenu_name"
                placeholder="Enter MasterMenu Name"
                value={formData.MasterMenu_name}
                onChange={handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter MasterMenu Name.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={8} lg={8}>
          <Form.Group controlId="MasterMenu_name">
            <Form.Label>Controller Name:</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i className="fas fa-building"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="ControllerName"
                placeholder="Enter Controller Name"
                value={formData.ControllerName}
                onChange={handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Controller Name.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={8} lg={8}>
          <Form.Group controlId="MasterMenu_name">
            <Form.Label>Action Name:</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i className="fas fa-building"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="ActionName"
                placeholder="Enter Action Name"
                value={formData.ActionName}
                onChange={handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Action Name.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={8} lg={8}>
          <Form.Group controlId="MasterMenu_name">
            <Form.Label>FriendlyUrl:</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i className="fas fa-building"></i>
              </InputGroup.Text>
              <Form.Control
                type="text"
                name="FriendlyUrl"
                placeholder="Enter FriendlyUrl"
                value={formData.FriendlyUrl}
                onChange={handleInputChange}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={8} lg={8}>
          <Form.Group controlId="MasterMenu_name">
            <Form.Label>Display Priority:</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <i className="fas fa-building"></i>
              </InputGroup.Text>
              <Form.Control
                type="number"
                name="DisplayPriority"
                placeholder="Enter MasterMenu Name"
                value={formData.DisplayPriority}
                onChange={handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Display Priority.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="isActive">
              <Form.Label></Form.Label>
              <Form.Check
                type="checkbox"
                label="Is Active"
                name="IsActive"
                checked={formData.IsActive}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
     
      
      <div className="  margin_top10 items-end flex justify-end">
        <Button
          type="button"
          variant="primary"
          className="mb-20px btn-tapti "
          onClick={insertMasterMenuMaster}
        >
          {formData?.MasterMenu_id > 0 ? "Update" : "Save"}
        </Button>
      </div>
    </Form>
  </>
  )
}
