import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import UserSetting from "../../UserSettings.json";
import Loader from "../../Components/Common/Loader ";
import EventComponent from "../Event/EventComponent";
import { convertDateFormat } from "../../Utils/commonFunction";
import { Edit, Delete } from "../../Components/Common/EditDelete";

export default function Event() {
  const [EventData, setEventData] = useState([]);
  const [editEventData, setEditEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);

  useEffect(() => {
    getEvent();
  }, []);

  // Fetch all Areas
  const getEvent = () => {
    ;
    setIsLoading(true);
    api
      .get("Event/AllEvents")
      .then((result) => {
        setEventData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSingleCompony = (id) => {
    ;
    api
      .get(`Event/SingleEvent?EventID=${id}`)
      .then((result) => {
        const EventData = result.data.data;
        setEditEventData(EventData);
        setShowEventModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const DeleteEvent = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Event/DeleteEvent?EventID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getEvent();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const columnDefs = [
    {
      headerName: "Event Name",
      field: "eventName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 50,
    },
    {
      headerName: "Details",
      field: "details",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 50,
      cellRenderer: (params) => {
        return <div dangerouslySetInnerHTML={{ __html: params.value }} />;
      },
    },
    
    {
      headerName: "edit",
      width: 70,
      field: "eventID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button onClick={() => getSingleCompony(value)}>
          <Edit />
        </button>
      ),
      pinned: "right",
    },
    {
      headerName: "Delete",
      width: 90,
      field: "eventID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button onClick={() => DeleteEvent(value)}>
          <Delete />
        </button>
      ),
      pinned: "right",
    },
  ];

  const HandlModelClose = () => {
    setShowEventModal(false);
    setEditEventData(null);
  };
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card.Header className="d-flex justify-content-between">
        <span>Event</span>
        <Button
          className="btn-tapti"
          onClick={() => setShowEventModal(true)}
        >
          Add Event
        </Button>{" "}
      </Card.Header>
      <Card.Body className="withBorder">
        <AgGridComponent
          rowData={EventData}
          columnDefs={columnDefs}
          paginationPageSize={15}
        />
      </Card.Body>

      <GlobalModal
        title="New Event"
        bodyComponent={
          <EventComponent
            onClose={HandlModelClose}
            returnID={getEvent}
            editedEventData={editEventData}
            clearData={showEventModal}
          />
        }
        isOpen={showEventModal}
        onClose={HandlModelClose}
      />
    </>
  );
}
