import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button, Image } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputGroupWithIcon from "../../Components/Common/InputGroupWithIcon ";
import CKEditorInput from "../../Components/Common/CKEditorInput";
import SubmitButtonOfForm from "../../Components/Common/SubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Components/Common/GlobalFormTagWithSubmit";
import ProductCategoryComponent from "../ProductCategory/ProductCategoryComponent";
import ImageAddAndPreview from "../../Components/Common/ImageAddAndPreview";
import UserSetting from "../../UserSettings.json";
import GlobalDDComponent from "../../Components/Common/GlobalDDComponent";
import GlobalCheckbox from "../../Components/Common/GlobalCheckbox";

const emptyEvent = {
  ProductID: 0,
  ProductName: "",
  ProductCategoryID: 0,
  Details: "",
  Description: "",
  Variant: "",
  Instock: "",
  ProductFlag: "No flag",
  IsActive: true,
  IsHomePage: false,
};

const variantOptions = ["Gold", "Silver", "Diamond"];
const stockOptions = ["In stock", "Out of stock"];
const productFlagOptions = ["Is Sale", "New", "No flag"];

export default function ProductComponent({
  editedProductData,
  returnID,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the form
  const [validated, setValidated] = useState(false);
  const ProductFormRef = useRef(null);
  const [formData, setFormData] = useState(emptyEvent);
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [ckValidated, setCkValidated] = useState(false);
  const imageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [ddValidationError, setDDValidationError] = useState(false);

  useEffect(() => {
    if (editedProductData) {
      debugger;
      setFormData({
        ProductID: editedProductData.productID,
        ProductName: editedProductData.productName,
        ProductCategoryID: editedProductData.productCategoryID,
        Variant: editedProductData.variant,
        Instock: editedProductData.instock,
        ProductFlag: editedProductData.productFlag,
        IsActive: editedProductData.isActive,
        IsHomePage: editedProductData.isHomePage,
      });
      if (editedProductData.details) {
        setDetails(editedProductData.details);
      }
      if (editedProductData.description) {
        setDescription(editedProductData.description);
      }
    }
  }, [editedProductData]);

  useEffect(() => {
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  // Insert & Update Data
  const insertProductMaster = (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setCkValidated(details == []);
      return;
    } else {
      const form_Data = new FormData();
      form_Data.append("ProductID", formData?.ProductID || 0);
      form_Data.append("ProductName", formData.ProductName);
      form_Data.append("Details", details);
      form_Data.append("Description", description);
      form_Data.append("Variant", formData.Variant);
      form_Data.append("Instock", formData.Instock);
      form_Data.append("ProductFlag", formData.ProductFlag);
      form_Data.append("IsActive", formData.IsActive);
      form_Data.append("IsHomePage", formData.IsHomePage);
      form_Data.append("ProductCategoryID", formData.ProductCategoryID);
      selectedImage.forEach((image, index) => {
        form_Data.append(`image${index}`, image);
      });

      const endpoint =
        formData.ProductID === 0
          ? "Product/InsertProduct"
          : "Product/EditProduct";

      api[formData.ProductID === 0 ? "post" : "patch"](endpoint, form_Data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            if (returnID) {
              returnID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset = () => {
    setFormData(emptyEvent);
    setDetails("");
    setSelectedImage(null);
    setImagePreview(null);
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const imagePreviews = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        imagePreviews.push(reader.result);
        if (imagePreviews.length === files.length) {
          setImagePreview(imagePreviews);
        }
      };
      reader.readAsDataURL(file);
    });

    setSelectedImage(files);
  };

  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        onSubmit={insertProductMaster}
        refName={ProductFormRef}
        formName="ProductForm"
      >
        <Row>
          <InputGroupWithIcon
            ColSize={6}
            icon="fas fa-building"
            type="text"
            name="ProductName"
            placeholder="Enter Product Name"
            value={formData.ProductName}
            onChange={handleInputChange}
            required
          />
          <GlobalDDComponent
            ColSize={6}
            formLabel="ProductCategory"
            apiEndpoint="ProductCategory/GetAllProductCategories"
            valueOfDD="productCategoryID"
            labelOfDD="productCategoryName"
            otherlabel="genderCatagory"
            onSelectOption={(productCategoryID) => {
              setFormData((prevData) => ({
                ...prevData,
                ProductCategoryID: productCategoryID,
              }));
              setDDValidationError(false);
            }}
            selectedOptionOfComponent={formData.ProductCategoryID}
            ValidationError={ddValidationError}
            offcanvasTitle="Add ProductCategory"
            offcanvasBodyComponent={<ProductCategoryComponent />}
          />
        </Row>
        <Row>
          <CKEditorInput
            ColSize={6}
            label="Details"
            data={details}
            onChange={setDetails}
            Validation={ckValidated}
          />
          <CKEditorInput
            ColSize={6}
            label="Description"
            data={description}
            onChange={setDescription}
            Validation={ckValidated}
          />
        </Row>
        <Row>
          <Col className="form-group col-6">
            <label
              style={{ fontWeight: "700", paddingBottom: "5px" }}
              htmlFor="variant"
            >
              Variant :
            </label>
            <select
              id="variant"
              name="Variant"
              value={formData.Variant}
              onChange={handleInputChange}
              className="form-control gender-select"
              required
            >
              <option value="" disabled>
                Select Variant
              </option>
              {variantOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </Col>
          <Col className="form-group col-6">
            <label
              style={{ fontWeight: "700", paddingBottom: "5px" }}
              htmlFor="stockStatus"
            >
              Stock Status :
            </label>
            <select
              id="stockStatus"
              name="Instock"
              value={formData.Instock}
              onChange={handleInputChange}
              className="form-control gender-select"
              required
            >
              <option value="" disabled>
                Select Stock Status
              </option>
              {stockOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row>
          <Col className="form-group col-6">
            <form
              style={{
                fontWeight: "700",
                paddingBottom: "5px",
                paddingTop: "10px",
              }}
              htmlFor="productFlag"
            >
              Product Flag
            </form>
            <select
              id="productFlag"
              name="ProductFlag"
              value={formData.ProductFlag}
              onChange={handleInputChange}
              className="form-control gender-select"
              required
            >
              <option value="" disabled>
                Select Product Flag
              </option>
              {productFlagOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        {!editedProductData && (
          <Row style={{ paddingbottom: "5px", paddingTop: "10px" }}>
            <ImageAddAndPreview
              imagePreview={imagePreview}
              onChange={handleImageChange}
              imageInputRef={imageInputRef}
              Label="Image"
              Multiple={true}
            />
          </Row>
        )}
        <Row style={{ paddingTop: "12px" }}>
          <GlobalCheckbox
            label="Is Active"
            name="IsActive"
            checked={formData.IsActive}
            onChange={handleInputChange}
          />
        </Row>
        <Row>
          <GlobalCheckbox
            label="Is HomePage"
            name="IsHomePage"
            checked={formData.IsHomePage}
            onChange={handleInputChange}
          />
        </Row>
        <SubmitButtonOfForm HeadID={formData?.ProductID} />
      </GlobalFormTagWithSubmit>
    </>
  );
}
