import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { loginUser } from "../../Features/userSlice";
import "./Login22.css";
import LOGO from "../../assets/logo.png";
import photo from "../../assets/photo2.png";

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [UserNameValidation, setUserNameValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const dispatch = useDispatch();

  const CheckLogin = async (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        userName: username,
        password: password,
      };
      dispatch(loginUser(data));
    }
  };

  const validate = () => {
    if (username.trim() === "" || password.trim() === "") {
      if (username.trim() === "") {
        setUserNameValidation(true);
      }
      if (password.trim() === "") {
        setPasswordValidation(true);
      }
      return false;
    }
    return true; // Validation passed
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <ToastContainer />
      <div className="LoginSection">
        {/* <div className="animetion">
          <img src={photo} alt="" />
        </div> */}
        <div className="login">
          <form>
            <div className="logo">
              {/* <img
                src={LOGO}
                height="60"
                alt="TAPTI TECHNOLOGY"
                loading="lazy"
              /> */}
            </div>
            <h2>
              Hello!
              <br />
              <span> Welcome Back!</span>
            </h2>
            <div>
              <div className="inputBox" >
                <input
                  type="text"
                  style={{ border: UserNameValidation ? "2px solid #dc3545" : "" }}
                  value={username}
                  onChange={(e) => {
                    setUserName(e.target.value);
                    setUserNameValidation(false);
                  }}
                  placeholder="User Name"
                />
                <i class="fa fa-user"></i>
              </div>
              {UserNameValidation && (
                <div className="ValidationErrorMsg mx-3">
                  Please select an UserName.
                </div>
              )}
            </div>
            <div>
              <div className="inputBox">
                <input
                  type={showPassword ? "text" : "password"}
                  style={{ border: passwordValidation ? "2px solid #dc3545" : "" }}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordValidation(false);
                  }}
                  placeholder="Password"
                />
                <i class="fa fa-lock"></i>
                <span
                  onClick={togglePasswordVisibility}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                  ></i>
                </span>
              </div>
              {passwordValidation && (
                <div className="ValidationErrorMsg mx-3">
                  Please select an Password.
                </div>
              )}
            </div>
            <div className="inputBox">
              <input type="submit" value="Log In" onClick={CheckLogin} />
            </div>
          </form>
        </div>
      </div>
      {/* <div className="form-box">
          <div className="form-value">
            <form>
              <h2 className="heading">Login</h2>
              <div className="input-box">
                <ion-icon name="mail-outline"></ion-icon>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="User Name"
                />
              </div>
              <div className="input-box">
                <ion-icon name="lock-closed-outline"></ion-icon>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </div>
              <button className="btnTheme" type="submit" onClick={CheckLogin}>
                Login
              </button>
            </form>
          </div>
        </div> */}
    </>
  );
};

export default Login;
