import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "../src/app/store";
import setAuthorizationToken from "./Utils/setAuthorizationToken";
import { LoadingProvider } from "../src/Loading/LoadingContext";
// import { SessionProvider } from "./session/SessionContext";

setAuthorizationToken(localStorage.getItem("token"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <LoadingProvider>
          <App />
        </LoadingProvider>
    </Provider>
  </React.StrictMode>
);
