import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import UserSetting from "../../UserSettings.json";
import Loader from "../../Components/Common/Loader ";
import GalleryComponent from "../Gallery/GalleryComponent";
import { convertDateFormat } from "../../Utils/commonFunction";
import { Edit, Delete } from "../../Components/Common/EditDelete";

export default function Gallery() {
  const [GalleryData, setGalleryData] = useState([]);
  const [editGalleryData, setEditGalleryData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showGalleryModal, setShowGalleryModal] = useState(false);

  useEffect(() => {
    getGallery();
  }, []);

  // Fetch all Areas
  const getGallery = () => {
    setIsLoading(true);
    api
      .get("Gallery/AllImages")
      .then((result) => {
        setGalleryData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSingleCompony = (id) => {
    api
      .get(`Gallery/SingleImage?ImageID=${id}`)
      .then((result) => {
        const GalleryData = result.data.data;
        setEditGalleryData(GalleryData);
        setShowGalleryModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const DeleteGallery = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Gallery/DeleteImage?ImageID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getGallery();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const columnDefs = [
    {
      headerName: "Event Name",
      field: "eventName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 50,
    },
    {
        headerName: "Image",
        flex: 1,
        minWidth: 150,
        field: "imagePath",
        autoHeight: true,
        cellStyle: { textAlign: "center" },
        headerClass: "center-text widthTableColum ",
        cellRenderer: ({ value }) => (
          <div className="p-2.5">
            <Image style={{width:"30%" ,height:"30%"}} src={UserSetting.imgURL + value}></Image>
          </div>
        ),
      },

    {
      headerName: "edit",
      width: 70,
      field: "imageID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button onClick={() => getSingleCompony(value)}>
          <Edit />
        </button>
      ),
      pinned: "right",
    },
    {
      headerName: "Delete",
      width: 90,
      field: "imageID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button onClick={() => DeleteGallery(value)}>
          <Delete />
        </button>
      ),
      pinned: "right",
    },
  ];

  const HandlModelClose = () => {
    setShowGalleryModal(false);
    setEditGalleryData(null);
  };
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card.Header className="d-flex justify-content-between">
        <span>Gallery</span>
        <Button className="btn-tapti" onClick={() => setShowGalleryModal(true)}>
          Add Gallery
        </Button>{" "}
      </Card.Header>
      <Card.Body className="withBorder">
        <AgGridComponent
          rowData={GalleryData}
          columnDefs={columnDefs}
          paginationPageSize={15}
        />
      </Card.Body>

      <GlobalModal
        title="New Gallery"
        bodyComponent={
          <GalleryComponent
            onClose={HandlModelClose}
            returnID={getGallery}
            editedGalleryData={editGalleryData}
            clearData={showGalleryModal}
          />
        }
        isOpen={showGalleryModal}
        onClose={HandlModelClose}
      />
    </>
  );
}
