import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLoadingContext } from "../../Loading/LoadingContext";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import MasterMenuComponent from "../MasterMenu/MasterMenuComponent";
import { Edit, Delete } from "../../Components/Common/EditDelete";
import {
  getAllData,
  getSingleData,
  deleteData,
} from "../../Utils/CallApiUtils";
import {
  createColumnDef,
  createColumnDefButton,
} from "../../Components/Common/AgGridColumnDef";

export default function MasterMenu() {
  const [MasterMenuData, setMasterMenuData] = useState([]);
  const [editMasterMenuData, setEditMasterMenuData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [showMasterMenuModal, setShowMasterMenuModal] = useState(false);

  useEffect(() => {
    getMasterMenu();
  }, []);

  // Fetch all Areas
  const getMasterMenu = async () => {
    await getAllData(
      "MasterMenu/AllMasterMenus",
      setIsLoading,
      setMasterMenuData
    );
  };

  const getSingleCompony = async (id) => {
    const Result = await getSingleData(
      `MasterMenu/SingleMasterMenu?MasterMenuID=${id}`,
      setEditMasterMenuData,
      setIsLoading
    );
    if (Result) {
      setShowMasterMenuModal(true);
    }
  };

  // Delete Area
  const DeleteMasterMenu = async (id) => {
    await deleteData(
      `MasterMenu/DeleteMasterMenu?MasterMenuID=${id}`,
      setIsLoading,
      getMasterMenu
    );
  };

  const columnDefs = [
    createColumnDef("MasterMenu Name", "masterMenuName", 150),
    createColumnDef("Controller Name", "controllerName", 150),
    createColumnDef("ActionName", "actionName", 150),
    createColumnDef("FriendlyUrl", "friendlyUrl", 150),
    createColumnDef("DisplayPriority", "displayPriority", 150),
    createColumnDefButton(
      "Edit",
      "masterMenuID",
      (value) => (
        <button onClick={() => getSingleCompony(value)}>
          <Edit />
        </button>
      )
    ),
    createColumnDefButton(
      "Delete",
      "masterMenuID",
      (value) => (
        <button onClick={() => DeleteMasterMenu(value)}>
          <Delete />
        </button>
      )
    ),
  ];

  const HandlModelClose = () => {
    setShowMasterMenuModal(false);
    setEditMasterMenuData(null);
  };
  return (
    <>
      <Card.Header className="d-flex justify-content-between">
        <span>MasterMenu</span>
        <Button
          className="btn-tapti"
          onClick={() => setShowMasterMenuModal(true)}
        >
          Add MasterMenu
        </Button>{" "}
      </Card.Header>
      <Card.Body className="withBorder">
        {MasterMenuData.length > 0 && (
          <AgGridComponent
            rowData={MasterMenuData}
            columnDefs={columnDefs}
            paginationPageSize={15}
          />
        )}
      </Card.Body>

      <GlobalModal
        title="New MasterMenu"
        bodyComponent={
          <MasterMenuComponent
            onClose={HandlModelClose}
            MasterMenuID={getMasterMenu}
            editedMasterMenuData={editMasterMenuData}
            clearData={showMasterMenuModal}
          />
        }
        isOpen={showMasterMenuModal}
        onClose={HandlModelClose}
      />
    </>
  );
}
