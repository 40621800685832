import React, { useEffect } from "react";
import { Form, Col, Image, Row } from "react-bootstrap";

export default function ImageAddAndPreview({
  imagePreview,
  onChange,
  imageInputRef,
  ColSize,
  Label,
  Multiple,
}) {

  useEffect(() => {
    console.log(imagePreview);
  }, [imagePreview]); // Add imagePreview to dependency array

  // Ensure imagePreview is an array
  const isArray = Array.isArray(imagePreview);

  return (
    <>
      <Col
        md={ColSize ? ColSize : 4}
        sm={ColSize ? ColSize : 4}
        lg={ColSize ? ColSize : 4}
      >
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>{Label}:</Form.Label>
          <Form.Control
            type="file"
            onChange={onChange}
            ref={imageInputRef}
            multiple={Multiple}
          />
        </Form.Group>
      </Col>
      {Multiple
        ? isArray && imagePreview.length > 0 && (
            <Row>
              {imagePreview.map((preview, index) => (
                <Col key={index} xs={6} md={ColSize ? ColSize - 12 : 4}>
                  <Image src={preview} thumbnail />
                </Col>
              ))}
            </Row>
          )
        : !isArray && imagePreview && (
            <Col xs={6} md={ColSize ? ColSize - 12 : 4}>
              <Image src={imagePreview} thumbnail />
            </Col>
          )}
    </>
  );
}


// How to use
// const MyComponent = () => {
// const imageInputRef = useRef(null);
// const [selectedImage, setSelectedImage] = useState(null);
// const [imagePreview, setImagePreview] = useState(null);

// const handleImageChange = (event) => {
//   setSelectedImage(event.target.files[0]);
//   const reader = new FileReader();
//   reader.onloadend = () => {
//     setImagePreview(reader.result);
//   };
//   reader.readAsDataURL(event.target.files[0]);
// };

// const reset = () => {
//   setSelectedImage(null);
//   setImagePreview(null);
//   if (imageInputRef.current) {
//     imageInputRef.current.value = null;
//   }
//   setFormData(emptyGallery);
// };

//   return (
//   <ImageAddAndPreview
//   imagePreview={imagePreview}
//   onChange={handleImageChange}
//   imageInputRef={imageInputRef}
//   Label = "Image"
// />
//   );
// };

// export default MyComponent;
