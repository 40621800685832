import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";

const AgGridComponent = ({
  grid,
  rowData,
  columnDefs,
  onSelectionChanged,
  autoGroupColumnDef,
  onGridReady,
  paginationPageSize,
}) => {
  return (
    <div className="ag-theme-alpine ag-responsive-grid">
      <AgGridReact
        ref={grid}
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={true}
        paginationPageSize={paginationPageSize}
        domLayout="autoHeight"
        rowSelection="multiple"
        animateRows={true}
        onGridReady={onGridReady}
      ></AgGridReact>
    </div>
  );
};

export default AgGridComponent;
