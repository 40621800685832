import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputGroupWithIcon from "../../Components/Common/InputGroupWithIcon ";
import CKEditorInput from "../../Components/Common/CKEditorInput";
import SubmitButtonOfForm from "../../Components/Common/SubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Components/Common/GlobalFormTagWithSubmit";

const emptyVideoMaster = {
  VideoMaster_ID: 0,
  VideoMasterName: "",
  DisplayPriority: 0,
};

export default function VideoMasterComponent({
  editedVideoMasterData,
  returnID,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyVideoMaster);
  const VideoMasterFormRef = useRef(null);
  const [details, setDetails] = useState("");
  const [ckValidated,setCkValidated] = useState(false);

  useEffect(() => {
    if (editedVideoMasterData) {
      setFormData({
        VideoMaster_ID: editedVideoMasterData.videoMasterID,
        VideoMasterName: editedVideoMasterData.videoMasterName,
        DisplayPriority  :editedVideoMasterData.displayPriority
      });
    }
  }, [editedVideoMasterData]);

  useEffect(() => {
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (VideoMaster) => {
    const { name, value, type, checked } = VideoMaster.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  // Insert & Update Data
  const insertVideoMasterMaster = (VideoMaster) => {
    setValidated(true);
    const form = VideoMaster.currentTarget;
    if (form.checkValidity() === false) {
      VideoMaster.stopPropagation();
      return;
    } else {
      const Data = {
        VideoMasterID: formData?.VideoMaster_ID || 0,
        VideoMasterName: formData.VideoMasterName,
        DisplayPriority: formData.DisplayPriority,
      };

      const endpoint =
        formData.VideoMaster_ID === 0 ? "VideoMaster/InsertVideoMaster" : "VideoMaster/EditVideoMaster";

      api[formData.VideoMaster_ID === 0 ? "post" : "patch"](endpoint, Data)
        .then((result) => {
          if (result.status === 200) {
            setValidated(false)
            toast.success(result.data.message);
            if (returnID) {
              returnID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset = () => {
    setFormData(emptyVideoMaster);
  };

  return (
    <>
    <GlobalFormTagWithSubmit
      validated={validated}
      onSubmit={insertVideoMasterMaster}
      refName={VideoMasterFormRef}
      formName="VideoMasterForm"
    >
      <Row>
        <InputGroupWithIcon
        ColSize={8}
          icon="fas fa-building"
          type="text"
          name="VideoMasterName"
          placeholder="Enter VideoMaster Name"
          value={formData.VideoMasterName}
          onChange={handleInputChange}
          required
        />
      </Row>
      <Row>
      <InputGroupWithIcon
        ColSize={8}
          icon="fas fa-building"
          type="number"
          name="DisplayPriority"
          placeholder="Enter DisplayPriority"
          value={formData.DisplayPriority}
          onChange={handleInputChange}
          required
        />
      </Row>
      <SubmitButtonOfForm HeadID={formData?.VideoMaster_ID} />
    </GlobalFormTagWithSubmit>
  </>
  )
}
