import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Row, Col, Form, InputGroup } from "react-bootstrap";

export default function CKEditorInput({
  ColSize,
  label,
  data,
  onChange,
  Validation,
}) {
  return (
    <>
      <Col
        md={ColSize ? ColSize : 4}
        sm={ColSize ? ColSize : 4}
        lg={ColSize ? ColSize : 4}
      >
        <Form.Group controlId="HeaderField">
          <Form.Label>{label}:</Form.Label>
          <InputGroup className="mb-3">
            <div style={{ width: "100%" }}>
              <CKEditor
              className ={`${Validation ? "borderRed" : ""}`}
                editor={ClassicEditor}
                data={data}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  onChange(data);
                }}
              />
            </div>
            {Validation &&  <div className="ValidationErrorMsg" style={{marginTop:"-3rem"}}>Please select a {label}.</div>}
          </InputGroup>
        </Form.Group>
      </Col>
    </>
  );
}

// hOW TO USE

// const MyComponent = () => {
  // const [details, setDetails] = useState("");
  // const [ckValidated,setCkValidated] = useState(false);

// const input function {
//   setCkValidated(details == []);
// }


//   return (
//      <CKEditorInput
// ColSize={8}
// label="Details"
// data={details}
// onChange={setDetails}
// Validation = {ckValidated}
// />
//   );
// };

// export default MyComponent;