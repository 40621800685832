import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import api from '../../Utils/api'; // Adjust the import path as needed
import Swal from 'sweetalert2';
import AgGridComponent from '../../Components/Common/AgGridComponent';
import GlobalModal from '../../Components/Common/GlobalModal';
import Loader from '../../Components/Common/Loader ';
import CarouselComponent from '../Carousel/CarouselComponent'; // Make sure the path is correct
import { Edit, Delete } from '../../Components/Common/EditDelete';
import { convertDateFormat } from '../../Utils/commonFunction';

export default function Carousel() {
  const [carouselData, setCarouselData] = useState([]);
  const [editCarouselData, setEditCarouselData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showCarouselModal, setShowCarouselModal] = useState(false);

  useEffect(() => {
    getCarouselData();
  }, []);

  // Fetch all carousel data
  const getCarouselData = () => {
    setIsLoading(true);
    api
      .get('Carousel/AllCarousels')
      .then((result) => {
        setCarouselData(result.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Fetch a single carousel item for editing
  const getSingleCarousel = (id) => {
    api
      .get(`Carousel/SingleCarousel?CarouselID=${id}`)
      .then((result) => {
        const carouselItem = result.data.data;
        setEditCarouselData(carouselItem);
        setShowCarouselModal(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Delete a carousel item
  const deleteCarousel = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to undo this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Carousel/DeleteCarousel?CarouselID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getCarouselData();
            }
          })
          .catch((error) => {
            console.error(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const columnDefs = [
    {
      headerName: "Carousel Details",
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>Writer Name:</strong> {params.data.carouselWriterName || "N/A"} <hr />
            <strong>Details:</strong>{" "}
            <div
              style={{ whiteSpace: "normal", wordWrap: "break-word" }}
              dangerouslySetInnerHTML={{ __html: params.data.carouselDetails }}
            />
          </div>
        );
      },
      flex: 1,
      minWidth: 400,
      autoHeight: true,
    },
    {
      headerName: "Email",
      field: "earouselEmailID",
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>Email ID:</strong> {params.data.carouselEmailID || "N/A"}
          </div>
        );
      },
      flex: 1,
      minWidth: 300,
    },
    {
      headerName: "Edit",
      width: 70,
      field: "carouselID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColumn",
      cellRenderer: ({ value }) => (
        <button onClick={() => getSingleCarousel(value)}><Edit /></button>
      ),
      pinned: "right",
    },
    {
      headerName: "Delete",
      width: 90,
      field: "carouselID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColumn",
      cellRenderer: ({ value }) => (
        <button onClick={() => deleteCarousel(value)}><Delete /></button>
      ),
      pinned: "right",
    },
  ];

  const handleModalClose = () => {
    setShowCarouselModal(false);
    setEditCarouselData(null);
  };

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card.Header className="d-flex justify-content-between">
        <span>Carousel</span>
        <Button className="btn-tapti" onClick={() => setShowCarouselModal(true)}>
          Add Carousel
        </Button>
      </Card.Header>
      <Card.Body className="withBorder">
        <AgGridComponent
          rowData={carouselData}
          columnDefs={columnDefs}
          paginationPageSize={4}
        />
      </Card.Body>

      <GlobalModal
        title="New Carousel"
        bodyComponent={
          <CarouselComponent
            onClose={handleModalClose}
            carouselID={getCarouselData}
            editedCarouselData={editCarouselData}
            clearData={showCarouselModal}
          />
        }
        isOpen={showCarouselModal}
        onClose={handleModalClose}
      />
    </>
  );
}
