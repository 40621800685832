import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import UserSetting from "../../UserSettings.json";
import Loader from "../../Components/Common/Loader "
import CompanyComponent from "./CompanyComponent";
import { Edit ,Delete } from "../../Components/Common/EditDelete";

export default function Company() {
  const [companyData, setCompanyData] = useState([]);
  const [editCompanyData, setEditCompanyData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);

  useEffect(() => {
    getCompany();
  }, []);

  // Fetch all Areas
  const getCompany = () => {
    ;
    setIsLoading(true);
    api
      .get("Company/AllCompanies")
      .then((result) => {
        setCompanyData(result.data.data);
        console.log(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSingleCompony = (id) => {
    
    api
      .get(`Company/SingleCompany?CompanyID=${id}`)
      .then((result) => {
        const CompanyData = result.data.data;
        setEditCompanyData(CompanyData);
        setShowCompanyModal(true)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const DeleteCompany = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Company/DeleteCompany?CompanyID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getCompany();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const columnDefs = [
    {
      headerName: "CompanyName",
      field: "companyName",
      headerClass: "widthTableColum",
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      minWidth: 50,
    },
    {
      headerName: "Logo",
       flex: 1,
      minWidth: 150,
      field: "logo",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum ",
      autoHeight:true,
      cellRenderer: ({ value }) => (
        <div  className="p-2.5">
          <Image style={{width:"50%",height:"50%"}} src={UserSetting.imgURL + value}></Image>
        </div>
      ),
    },
    {
      headerName: "Contact", // Header spanning across multiple columns
      field: "companyName",
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>Email ID:</strong> {params.data.emailID || "N/A"} <hr />
            <strong>MobileNo:</strong> {params.data.mobileNo || "N/A"}
            <hr />
            <p style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
              {" "}
              <strong>Address:</strong> {params.data.address || "N/A"}{" "}
            </p>
          </div>
        );
      },
      flex: 1,
      minWidth: 400,
      autoHeight: true,
    },
    {
      headerName: "Social Media", // Header spanning across multiple columns
      field: "socialMedia", // Assuming you have a field in your data that contains social media information
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>Instagram:</strong> {params.data.instagram || "N/A"} <hr />
            <strong>Twitter:</strong> {params.data.twitter || "N/A"} <hr />
            <strong>Facebook:</strong> {params.data.facebook || "N/A"}
          </div>
        );
      },
      flex: 1,
      minWidth: 300,
    },
    {
      headerName: "Edit",
      width: 70,
      field: "companyID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button  onClick={() => getSingleCompony(value)}><Edit/></button>
      ),
      pinned: "right",
    },
    {
      headerName: "Delete",
      width: 90,
      field: "companyID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button  onClick={() => DeleteCompany(value)}><Delete/></button>
      ),
      pinned: "right",
    },
  ];

  const HandlModelClose = () => {
    setShowCompanyModal(false);
    setEditCompanyData(null);
  };

  return (
    <>
    {isLoading && <Loader/>}
      <ToastContainer />
      <Card.Header className="d-flex justify-content-between">
        <span>Company</span>
        <Button className="btn-tapti" onClick={() => setShowCompanyModal(true)}>
          Add Company
        </Button>{" "}
      </Card.Header>
      <Card.Body className="withBorder">
        <AgGridComponent
          rowData={companyData}
          columnDefs={columnDefs}
          paginationPageSize={4}
        />
      </Card.Body>

      <GlobalModal
        title="New Company"
        bodyComponent={
          <CompanyComponent onClose={HandlModelClose} CompanyID={getCompany} editedCompanyData={editCompanyData}  clearData = {showCompanyModal}/>
        }
        isOpen={showCompanyModal}
        onClose={HandlModelClose}
      />
    </>
  );
}
