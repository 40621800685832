import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button ,Image} from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserSetting from "../../UserSettings.json";

const emptyCompany = {
  Company_id: 0,
  Company_name: "",
  Mobile_No: "",
  EmailId: "",
  Address: "",
  FaceBook: "",
  Twitter: "",
  Instagram: "",
  SMSAlertMobileNo: "",
};

export default function CompanyComponent({
  CompanyID,
  editedCompanyData,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyCompany);
  const CompanyFormRef = useRef(null);
  const imageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); 

  useEffect(() => {
    
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

    useEffect(() => {
      
      if (editedCompanyData) {
        setFormData({
          Company_id: editedCompanyData.companyID,
          Company_name: editedCompanyData.companyName,
          Mobile_No: editedCompanyData.mobileNo,
          EmailId: editedCompanyData.emailID,
          Address: editedCompanyData.address,
          FaceBook: editedCompanyData.facebook,
          Twitter: editedCompanyData.twitter,
          Instagram: editedCompanyData.instagram,
          SMSAlertMobileNo:editedCompanyData.smsAlertMobileNo
        });
        setImagePreview(UserSetting.imgURL + editedCompanyData.logo)
        console.log(imagePreview)
        
      }
    }, [editedCompanyData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Insert & Update Data
  const insertCompanyMaster = (event) => {
    
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
      
    } else {
      const form_Data = new FormData();
      form_Data.append("CompanyID", formData?.Company_id || 0);
      form_Data.append("CompanyName", formData.Company_name);
      form_Data.append("MobileNo", formData.Mobile_No);
      form_Data.append("EmailID", formData.EmailId);
      form_Data.append("Address", formData.Address);
      form_Data.append("Facebook", formData.FaceBook);
      form_Data.append("Twitter", formData.Twitter);
      form_Data.append("Instagram", formData.Instagram);
      form_Data.append("SMSAlertMobileNo", formData.SMSAlertMobileNo);
      form_Data.append("image", selectedImage);
    if(formData.Company_id > 0){
      form_Data.append("logo", editedCompanyData.logo);
    }

      const endpoint =
        formData.Company_id === 0
          ? "Company/InsertCompany"
          : "Company/EditCompany";

      api[formData.Company_id === 0 ? "post" : "patch"](endpoint, form_Data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            if (CompanyID) {
              CompanyID(result.data.data);
            }
            CompanyID(result.data.data);
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset =()=>{
    setFormData(emptyCompany);
    setSelectedImage(null);
    setImagePreview(null);
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
  }

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
    // Preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        // onSubmit={(event) => insertCompanyMaster(event, { resetForm })}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (CompanyFormRef.current && e) {
            // Check for the formName to call insertCity
            if (e.target.name === "CompanyForm") {
              insertCompanyMaster(e);
            }
          }
        }}
        ref={CompanyFormRef}
        name="CompanyForm"
      >
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="Company_name">
              <Form.Label>Company Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Company_name"
                  placeholder="Enter Company Name"
                  value={formData.Company_name}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Company Name.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="Company_name">
              <Form.Label>Mobile No:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Mobile_No"
                  placeholder="Enter Mobile No"
                  value={formData.Mobile_No}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Mobile No.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="Company_name">
              <Form.Label>Email ID:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="EmailId"
                  placeholder="Enter EmailId"
                  value={formData.EmailId}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="Company_name">
              <Form.Label>Address:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="Address"
                  placeholder="Enter Address"
                  value={formData.Address}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={3} lg={3}>
            <Form.Group controlId="Company_name">
              <Form.Label>FaceBook:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="FaceBook"
                  placeholder="Enter FaceBook "
                  value={formData.FaceBook}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={3} sm={3} lg={3}>
            <Form.Group controlId="Company_name">
              <Form.Label>Instagram:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Instagram"
                  placeholder="Enter Instagram ID"
                  value={formData.Instagram}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col md={3} sm={3} lg={3}>
            <Form.Group controlId="Company_name">
              <Form.Label>Twitter:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Twitter"
                  placeholder="Enter Twitter"
                  value={formData.Twitter}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md={3} sm={3} lg={3}>
            <Form.Group controlId="Company_name">
              <Form.Label>SMSAlert MobileNo:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="SMSAlertMobileNo"
                  placeholder="Enter SMSAlert MobileNo"
                  value={formData.SMSAlertMobileNo}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Company Logo:</Form.Label>
              <Form.Control type="file" onChange={handleImageChange} ref={imageInputRef} />
            </Form.Group>
          </Col>
        </Row>
          <Row>
          <Col xs={6} md={4}>
          <Form.Label>Company Logo:</Form.Label>
            <Image src={imagePreview} thumbnail />
          </Col>
        </Row>
        <div className="  margin_top10 items-end flex justify-end">
          <Button
            type="submit"
            variant="primary"
            className="mb-20px btn-tapti "
          >
            {formData?.Company_id > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
