import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button, Image } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserSetting from "../../UserSettings.json";

const emptyClientLogo = {
  MediclaimImage_ID: 0,
  DisplayPriority: 0,
};

export default function ClientLogoComponent({
  ClientLogoID,
  editedClientLogoData,
  clearData,
  onClose,
  isMultiAdd
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyClientLogo);
  const ClientLogoFormRef = useRef(null);
  const imageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    ;
    if (editedClientLogoData) {
      ;
      setFormData({
        MediclaimImage_ID: editedClientLogoData.mediclaimImageID,
        DisplayPriority: editedClientLogoData.displayPriority,
      });
      setImagePreview(
        UserSetting.imgURL + editedClientLogoData.mediclaimImagePath
      );
    }
  }, [editedClientLogoData]);

  useEffect(() => {
    
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Insert & Update Data
  const insertClientLogoMaster = (event) => {
    ;
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    } else {
      const form_Data = new FormData();
      form_Data.append("MediclaimImageID", formData?.MediclaimImage_ID || 0);
      form_Data.append("DisplayPriority", formData.DisplayPriority);
      form_Data.append("image", selectedImage);
      // if (formData.MediclaimImage_ID > 0) {
      //   form_Data.append("logo", editedClientLogoData.logo);
      // }

      const endpoint =
        formData.MediclaimImage_ID === 0
          ? "MediclaimImage/InsertMediclaimImage"
          : "MediclaimImage/EditMediclaimImage";

      api[formData.MediclaimImage_ID === 0 ? "post" : "patch"](
        endpoint,
        form_Data
      )
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            if (ClientLogoID) {
              ClientLogoID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          if(!isMultiAdd){
            onClose();
        }
        });
    }
  };

  const reset = () => {
    ;
    setSelectedImage(null);
    setImagePreview(null);
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
    setFormData(emptyClientLogo);
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
    // Preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        // onSubmit={(event) => insertClientLogoMaster(event, { resetForm })}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (ClientLogoFormRef.current && e) {
            // Check for the formName to call insertCity
            if (e.target.name === "ClientLogoForm") {
              insertClientLogoMaster(e);
            }
          }
        }}
        ref={ClientLogoFormRef}
        name="ClientLogoForm"
      >
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="ClientLogo_name">
              <Form.Label>DisplayPriority:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="DisplayPriority"
                  placeholder="Enter ClientLogo Name"
                  value={formData.DisplayPriority}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter ClientLogo Name.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>ClientLogo Logo:</Form.Label>
              <Form.Control
                type="file"
                onChange={handleImageChange}
                ref={imageInputRef}
              />
            </Form.Group>
          </Col>
        
          {imagePreview && <Col xs={6} md={4}>
            <Form.Label>ClientLogo Logo:</Form.Label>
            <Image src={imagePreview} thumbnail />
          </Col>}
        </Row>
        <div className="  margin_top10 items-end flex justify-end">
          <Button
            type="submit"
            variant="primary"
            className="mb-20px btn-tapti "
          >
            {formData?.MediclaimImage_ID > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
