import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputGroupWithIcon from "../../Components/Common/InputGroupWithIcon ";
import SubmitButtonOfForm from "../../Components/Common/SubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Components/Common/GlobalFormTagWithSubmit";
import ImageAddAndPreview from "../../Components/Common/ImageAddAndPreview";
import UserSetting from "../../UserSettings.json";
import CKEditorInput from "../../Components/Common/CKEditorInput";
import GlobalCheckbox from "../../Components/Common/GlobalCheckbox";
import { getCurrentDate } from "../../Utils/commonFunction";
import Swal from "sweetalert2"; // Import SweetAlert2

const emptyBlogData = {
  BlogID: 0,
  BlogTitleName: "",
  BlogDate: getCurrentDate(),
  BlogImagePath: "",
  BlogDetails: "",
  IsActive: true,
  IsHome: false,
};

export default function BlogComponent({
  editedblogData,
  returnID,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [validated, setValidated] = useState(false);
  const BlogFormRef = useRef(null);
  const imageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState(emptyBlogData);
  const [blogdetails, setBlogDetails] = useState("");
  const [ckValidated, setCkValidated] = useState(false);

  useEffect(() => {
    if (editedblogData) {
      setFormData({
        BlogID: editedblogData.blogID,
        BlogTitleName: editedblogData.blogTitleName,
        BlogDate: editedblogData.blogDate
          ? editedblogData.blogDate.split("T")[0]
          : "",
        BlogImagePath: editedblogData.blogImagePath,
        IsActive: editedblogData.isActive,
        IsHome: editedblogData.isHome,
      });
      if (editedblogData.blogDetails) {
        setBlogDetails(editedblogData.blogDetails);
      }
      setImagePreview(UserSetting.imgURL + editedblogData.blogImagePath);
    }
  }, [editedblogData]);

  useEffect(() => {
    if (clearData === false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;

    // Handle Home Page checkbox change
    if (name === "IsHome" && checked) {
      Swal.fire({
        title: "Design Tips",
        html: `
          <p>To enhance the blog's appearance on the home page, follow these guidelines:</p>
          <ul>
            <li><strong>Title:</strong> Ensure it has 1-5 words.</li>
            <li><strong>Details:</strong> Limit it to 1-15 words for a better designing experience.</li>
          </ul>
        `,
        icon: "info",
        confirmButtonText: "Got it!",
      });
    }

    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  const insertBlogData = (event) => {
    event.preventDefault();
    setValidated(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false || !blogdetails) {
      event.stopPropagation();
      setCkValidated(!blogdetails);
      return;
    }

    const form_Data = new FormData();
    form_Data.append("BlogID", formData?.BlogID || 0);
    form_Data.append("BlogTitleName", formData.BlogTitleName);
    form_Data.append("BlogDate", formData.BlogDate);
    form_Data.append("BlogDetails", blogdetails);
    form_Data.append("IsActive", formData.IsActive);
    form_Data.append("IsHome", formData.IsHome);
    if (selectedImage) {
      form_Data.append("image", selectedImage);
    }

    const endpoint =
      formData.BlogID === 0 ? "Blog/InsertBlog" : "Blog/EditBlog";

    api({
      method: formData.BlogID === 0 ? "post" : "patch",
      url: endpoint,
      data: form_Data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        if (result.status === 200) {
          setValidated(false);
          toast.success(result.data.message);
          if (returnID) {
            returnID(result.data.data);
          }
        } else {
          toast.error(result.data.message);
        }
        setRefreshKey((prev) => prev + 1);
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred");
      })
      .finally(() => {
        reset();
        onClose();
      });
  };

  const reset = () => {
    setFormData(emptyBlogData);
    setSelectedImage(null);
    setImagePreview(null);
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <GlobalFormTagWithSubmit
      validated={validated}
      onSubmit={insertBlogData}
      refName={BlogFormRef}
      formName="BlogForm"
    >
      <Row>
        <InputGroupWithIcon
          ColSize={8}
          icon="fas fa-heading"
          type="text"
          name="BlogTitleName"
          placeholder="Enter Blog Title"
          value={formData.BlogTitleName}
          onChange={handleInputChange}
          required
        />
      </Row>
      <Row>
        <InputGroupWithIcon
          ColSize={8}
          icon="fas fa-calendar"
          type="date"
          name="BlogDate"
          placeholder="Enter Blog Date"
          value={formData.BlogDate}
          onChange={handleInputChange}
          required
        />
      </Row>
      <Row>
        <CKEditorInput
          ColSize={10}
          label="Details"
          data={blogdetails}
          onChange={setBlogDetails}
          Validation={ckValidated}
        />
      </Row>
      <Row>
        <ImageAddAndPreview
          imagePreview={imagePreview}
          onChange={handleImageChange}
          imageInputRef={imageInputRef}
          Label="Blog Image"
        />
      </Row>
      <GlobalCheckbox
        label="Is Active"
        name="IsActive"
        checked={formData.IsActive}
        onChange={handleInputChange}
      />
      <GlobalCheckbox
        label="Home Page"
        name="IsHome"
        checked={formData.IsHome}
        onChange={handleInputChange}
      />
      <SubmitButtonOfForm HeadID={formData?.BlogID} />
    </GlobalFormTagWithSubmit>
  );
}
