import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button, Image } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserSetting from "../../UserSettings.json";
import InputGroupWithIcon from "../../Components/Common/InputGroupWithIcon ";
import CKEditorInput from "../../Components/Common/CKEditorInput";
import SubmitButtonOfForm from "../../Components/Common/SubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Components/Common/GlobalFormTagWithSubmit";
import ImageAddAndPreview from "../../Components/Common/ImageAddAndPreview";
import { getCurrentDateTime} from "../../Utils/commonFunction"
import GlobalCheckbox from "../../Components/Common/GlobalCheckbox";


const emptyHomePopUp = {
    HomePopUpID: 0,
    HomePopUpTitle: "",
    HomePopUpDetails: "",
    HomePopUpStartDate : getCurrentDateTime(),
    HomePopUpEndDate : getCurrentDateTime(),
    HomePopUpIsActive  :true
  };

export default function HomePopUpComponent({
    returnID,
    editedHomePopUpData,
    clearData,
    onClose,
  }) {
    const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState(emptyHomePopUp);
    const HomePopUpFormRef = useRef(null);
    const imageInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [ddValidationError, setDDValidationError] = useState(false);
    const [details, setDetails] = useState("");
    const [ckValidated,setCkValidated] = useState(false);

    useEffect(() => {
        if (editedHomePopUpData) {
          setFormData({
            HomePopUpID: editedHomePopUpData.homePopUpID,
            HomePopUpTitle: editedHomePopUpData.homePopUpTitle,
            HomePopUpStartDate: editedHomePopUpData.homePopUpStartDate,
            HomePopUpEndDate: editedHomePopUpData.homePopUpEndDate,
            HomePopUpIsActive: editedHomePopUpData.homePopUpIsActive,
          });
          setImagePreview(UserSetting.imgURL + editedHomePopUpData.homePopUpImagePath);
          setDetails(editedHomePopUpData.homePopUpDetails)
        }
      }, [editedHomePopUpData]);
    
      useEffect(() => {
        if (clearData == false) {
          reset();
        }
      }, [clearData]);
    
      const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const inputValue = type === "checkbox" ? checked : value;
        setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
      };
    
      // Insert & Update Data
      const insertHomePopUpMaster = (event) => {
        setValidated(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.stopPropagation();
          setCkValidated(details == []);
          return;
        } else {
          const form_Data = new FormData();
          form_Data.append("HomePopUpID", formData?.HomePopUpID || 0);
          form_Data.append("HomePopUpTitle", formData.HomePopUpTitle);
          form_Data.append("HomePopUpDetails", details);
          form_Data.append("HomePopUpEndDate", formData.HomePopUpEndDate);
          form_Data.append("HomePopUpIsActive", formData.HomePopUpIsActive);
          form_Data.append("HomePopUpStartDate", formData.HomePopUpStartDate);
          form_Data.append("image", selectedImage);
    
          const endpoint =
            formData.HomePopUpID === 0 ? "HomePopUp/InsertHomePopUp" : "HomePopUp/EditHomePopUp";
    
          api[formData.HomePopUpID === 0 ? "post" : "patch"](endpoint, form_Data)
            .then((result) => {
              if (result.status === 200) {
                toast.success(result.data.message);
                if (returnID) {
                  returnID(result.data.data);
                }
              } else {
                toast.error(result.data.message);
              }
              setRefreshKey(refreshKey + 1);
            })
            .catch((error) => {
              console.log(error);
              toast.error("An error occurred");
            })
            .finally(() => {
              reset();
              onClose();
            });
        }
      };
    
      const reset = () => {
        setSelectedImage(null);
        setImagePreview(null);
        if (imageInputRef.current) {
          imageInputRef.current.value = null;
        }
        setFormData(emptyHomePopUp);
      };
    
      const handleImageChange = (event) => {
        setSelectedImage(event.target.files[0]);
        // Preview image
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
      };
    
  return (
    <>
    <GlobalFormTagWithSubmit
      validated={validated}
      onSubmit={insertHomePopUpMaster}
      refName={HomePopUpFormRef}
      formName="HomePopUpForm"
    >
      <Row>
        <InputGroupWithIcon 
          ColSize={8}
          icon="fas fa-building"
          type="text"
          name="HomePopUpTitle"
          placeholder="Enter Title"
          value={formData.HomePopUpTitle}
          onChange={handleInputChange}
          required
        />
      </Row>
      <Row>
          <CKEditorInput
            ColSize={8}
            label="Details"
            data={details}
            onChange={setDetails}
            Validation = {ckValidated}
          />
        </Row>
        <Row>
        <InputGroupWithIcon 
          ColSize={8}
          icon="fas fa-building"
          type="datetime-local"
          name="HomePopUpStartDate"
          placeholder="Enter Start Date"
          value={formData.HomePopUpStartDate}
          onChange={handleInputChange}
          required
        />
      </Row>
      <Row>
        <InputGroupWithIcon 
          ColSize={8}
          icon="fas fa-building"
          type="datetime-local"
          name="HomePopUpEndDate"
          placeholder="Enter End Date"
          value={formData.HomePopUpEndDate}
          onChange={handleInputChange}
          required
        />
      </Row>
      <Row>
        <ImageAddAndPreview
          imagePreview={imagePreview}
          onChange={handleImageChange}
          imageInputRef={imageInputRef}
          Label = "Image"
        />
      </Row>
      <Row>
          <GlobalCheckbox
            label="Is Active"
            name="HomePopUpIsActive"
            checked={formData.HomePopUpIsActive}
            onChange={handleInputChange}
          />
        </Row>
      <SubmitButtonOfForm HeadID={formData?.HomePopUpID} />
    </GlobalFormTagWithSubmit>
  </>
  )
}
