import React from "react";


export function Edit({width ,height}) {
  return (
   <lord-icon
   src="https://cdn.lordicon.com/vhyuhmbl.json"
      trigger="hover"
      style={{ width: width ? width : "55px", height: height ? height : "40px" }}
    ></lord-icon>
  );
}

// How to use 
{/* <Edit  width="0px" height="0px" /> */}

export function Delete({width ,height}) {
  return (
    <lord-icon
      src="https://cdn.lordicon.com/hjbrplwk.json"
      colors="primary:#646e78,secondary:#e83a30,tertiary:#ebe6ef,quaternary:#3a3347"
      trigger="hover"
      style={{ width: width ? width : "50px", height: height ? height : "40px" }}
    ></lord-icon>
  );
}

// How to use 
{/* <Delete  width="0px" height="0px" /> */}
