import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputGroupWithIcon from "../../Components/Common/InputGroupWithIcon ";
import SubmitButtonOfForm from "../../Components/Common/SubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Components/Common/GlobalFormTagWithSubmit";
import GlobalDDComponent from "../../Components/Common/GlobalDDComponent";
import VideoMasterComponent from "../VideoMaster/VideoMasterComponent";
import GlobalCheckbox from "../../Components/Common/GlobalCheckbox";

const emptyVideos = {
  Videos_ID: 0,
  VideosName: "",
  URL: "",
  VideoMasterID: 0,
  IsHome: false,
};

export default function VideosComponent({
  editedVideosData,
  returnID,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyVideos);
  const VideosFormRef = useRef(null);
  const [ddValidationError, setDDValidationError] = useState(false);

  useEffect(() => {
    if (editedVideosData) {
      setFormData({
        Videos_ID: editedVideosData.videoID,
        VideosName: editedVideosData.videoName,
        URL: editedVideosData.url,
        VideoMasterID: editedVideosData.videoMasterID,
        IsHome: editedVideosData.isHome,
      });
    }
  }, [editedVideosData]);

  useEffect(() => {
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (Videos) => {
    const { name, value, type, checked } = Videos.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  // Insert & Update Data
  const insertVideosMaster = (Videos) => {
    setValidated(true);
    const form = Videos.currentTarget;
    if (form.checkValidity() === false) {
      Videos.stopPropagation();
      return;
    } else {
      const Data = {
        VideoID: formData?.Videos_ID || 0,
        VideoName: formData.VideosName,
        URL: formData.URL,
        VideoMasterID: formData.VideoMasterID,
        IsHome: formData.IsHome,
      };

      const endpoint =
        formData.Videos_ID === 0 ? "Video/InsertVideo" : "Video/EditVideo";

      api[formData.Videos_ID === 0 ? "post" : "patch"](endpoint, Data)
        .then((result) => {
          if (result.status === 200) {
            setValidated(false);
            toast.success(result.data.message);
            if (returnID) {
              returnID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset = () => {
    setFormData(emptyVideos);
  };
  return (
    <>
      <GlobalFormTagWithSubmit
        validated={validated}
        onSubmit={insertVideosMaster}
        refName={VideosFormRef}
        formName="VideosForm"
      >
        <Row>
          <InputGroupWithIcon
            ColSize={8}
            icon="fas fa-building"
            type="text"
            name="VideosName"
            placeholder="Enter Videos Name"
            value={formData.VideosName}
            onChange={handleInputChange}
            required
          />
        </Row>
        <Row>
          <InputGroupWithIcon
            ColSize={8}
            icon="fas fa-building"
            type="text"
            name="URL"
            placeholder="Enter URL of Video "
            value={formData.URL}
            onChange={handleInputChange}
            required
          />
        </Row>
        <Row>
          <GlobalDDComponent
            ColSize={8}
            formLabel="Video Master"
            apiEndpoint="VideoMaster/AllVideoMasters"
            valueOfDD="videoMasterID"
            labelOfDD="videoMasterName"
            onSelectOption={(videoMasterID) => {
              setFormData((prevData) => ({
                ...prevData,
                VideoMasterID: videoMasterID,
              }));
              setDDValidationError(false);
            }}
            selectedOptionOfComponent={formData.VideoMasterID}
            ValidationError={ddValidationError}
            offcanvasTitle="Add VideoMasterID"
            offcanvasBodyComponent={<VideoMasterComponent />}
          />
        </Row>
        <Row>
          <GlobalCheckbox
            label="Is Home"
            name="IsHome"
            checked={formData.IsHome}
            onChange={handleInputChange}
          />
        </Row>
        <SubmitButtonOfForm HeadID={formData?.Videos_ID} />
      </GlobalFormTagWithSubmit>
    </>
  );
}
