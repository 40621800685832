import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import UserSetting from "../../UserSettings.json";
import Loader from "../../Components/Common/Loader ";
import HomePopUpComponent from "../HomePopUp/HomePopUpComponent";
import { convertDateFormat } from "../../Utils/commonFunction";
import { Edit, Delete } from "../../Components/Common/EditDelete";

export default function HomePopUp() {
  const [HomePopUpData, setHomePopUpData] = useState([]);
  const [editHomePopUpData, setEditHomePopUpData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showHomePopUpModal, setShowHomePopUpModal] = useState(false);

  useEffect(() => {
    getHomePopUp();
  }, []);

  // Fetch all Areas
  const getHomePopUp = () => {
    setIsLoading(true);
    api
      .get("HomePopUp/AllHomePopUps")
      .then((result) => {
        setHomePopUpData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSingleCompony = (id) => {
    api
      .get(`HomePopUp/SingleHomePopUp?HomePopUpID=${id}`)
      .then((result) => {
        const HomePopUpData = result.data.data;
        setEditHomePopUpData(HomePopUpData);
        setShowHomePopUpModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const DeleteHomePopUp = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`HomePopUp/DeleteHomePopUp?HomePopUpID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getHomePopUp();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };
  const HandlModelClose = () => {
    setShowHomePopUpModal(false);
    setEditHomePopUpData(null);
  };

  const columnDefs = [
    {
      headerName: "HomePopUp Details", // Header spanning across multiple columns
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>Title:</strong> {params.data.homePopUpTitle || "N/A"} <hr />
            <strong>Details:</strong>{" "}
            <div
              style={{ whiteSpace: "normal", wordWrap: "break-word" }}
              dangerouslySetInnerHTML={{ __html: params.data.homePopUpDetails }}
            />
          </div>
        );
      },
      flex: 1,
      minWidth: 400,
      autoHeight: true,
    },
    {
        headerName: "Image",
        flex: 1,
        minWidth: 150,
        field: "homePopUpImagePath",
        autoHeight: true,
        cellStyle: { textAlign: "center" },
        headerClass: "center-text widthTableColum ",
        cellRenderer: ({ value }) => (
          <div className="p-2.5">
            <Image style={{width:"50%" , height:"50%"}} src={UserSetting.imgURL + value}></Image>
          </div>
        ),
      },
    {
      headerName: "Date Details", // Header spanning across multiple columns
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
                <strong>Start Date:</strong>
                {convertDateFormat(params.data.homePopUpStartDate)}
                <hr />
                <strong>End Date: </strong>
                {convertDateFormat(params.data.homePopUpEndDate)}
          </div>
        );
      },
      flex: 1,
      minWidth: 400,
      autoHeight: true,
    },
    {
      headerName: "Edit",
      width: 70,
      field: "homePopUpID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button  onClick={() => getSingleCompony(value)}><Edit/></button>
      ),
      pinned: "right",
    },
    {
      headerName: "Delete",
      width: 90,
      field: "homePopUpID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button  onClick={() => DeleteHomePopUp(value)}><Delete/></button>
      ),
      pinned: "right",
    },
  ];
  
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card.Header className="d-flex justify-content-between">
        <span>HomePopUp</span>
        <Button className="btn-tapti" onClick={() => setShowHomePopUpModal(true)}>
          Add HomePopUp
        </Button>{" "}
      </Card.Header>
      <Card.Body className="withBorder">
        <AgGridComponent
          rowData={HomePopUpData}
          columnDefs={columnDefs}
          paginationPageSize={15}
        />
      </Card.Body>

      <GlobalModal
        title="New HomePopUp"
        bodyComponent={
          <HomePopUpComponent
            onClose={HandlModelClose}
            returnID={getHomePopUp}
            editedHomePopUpData={editHomePopUpData}
            clearData={showHomePopUpModal}
          />
        }
        isOpen={showHomePopUpModal}
        onClose={HandlModelClose}
      />
    </>
  );
}
