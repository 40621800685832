import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button, Image } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputGroupWithIcon from "../../Components/Common/InputGroupWithIcon ";
import CKEditorInput from "../../Components/Common/CKEditorInput";
import SubmitButtonOfForm from "../../Components/Common/SubmitButtonOfForm";
import GlobalFormTagWithSubmit from "../../Components/Common/GlobalFormTagWithSubmit";
import OnClickSubmitButtonOfForm from "../../Components/Common/OnClickSubmitButtonOfForm";


const emptyEvent = {
  eventID: 0,
  EventName: "",
  Details: "",
};

export default function EventComponent({
  editedEventData,
  returnID,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const EventFormRef = useRef(null);
  const [formData, setFormData] = useState(emptyEvent);
  const [details, setDetails] = useState("");
  const [ckValidated, setCkValidated] = useState(false);

  useEffect(() => {
    if (editedEventData) {
      setFormData({
        eventID: editedEventData.eventID,
        EventName: editedEventData.eventName,
      });
      setDetails(editedEventData.details);
    }
  }, [editedEventData]);

  useEffect(() => {
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  // Insert & Update Data
  const insertEventMaster = (event) => {
    setValidated(true);
    const form = EventFormRef.current;
    if (!form.checkValidity()) {
      setCkValidated(details == []);
      return;
    }
     else {
      const Data = {
        EventID: formData?.eventID || 0,
        EventName: formData.EventName,
        Details: details,
      };

      const endpoint =
        formData.eventID === 0 ? "Event/InsertEvent" : "Event/EditEvent";

      api[formData.eventID === 0 ? "post" : "patch"](endpoint, Data)
        .then((result) => {
          if (result.status === 200) {
            setValidated(false);
            toast.success(result.data.message);
            if (returnID) {
              returnID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset = () => {
    setFormData(emptyEvent);
  };

  return (
    <>
      <GlobalFormTagWithSubmit
      validated={validated}
      // onSubmit={insertEventMaster}
      refName={EventFormRef}
      formName="EventForm"
      >
        <Row>
          <InputGroupWithIcon
            ColSize={8}
            icon="fas fa-building"
            type="text"
            name="EventName"
            placeholder="Enter Event Name"
            value={formData.EventName}
            onChange={handleInputChange}
            required
          />
        </Row>
      
        <Row>
          <CKEditorInput
            ColSize={8}
            label="Details"
            data={details}
            onChange={setDetails}
            Validation={ckValidated}
          />
        </Row>
        <OnClickSubmitButtonOfForm onClick={insertEventMaster} HeadID={formData?.eventID} />
      </GlobalFormTagWithSubmit>
    </>
  );
}
