import React, { useState, useEffect } from "react";
import { Card, Button, Image, Form, InputGroup, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import UserSetting from "../../UserSettings.json";
import { useLoadingContext } from "../../Loading/LoadingContext";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import BlogComponent from "../Blog/BlogComponent";
import { Edit, Delete } from "../../Components/Common/EditDelete";
import {
  getAllData,
  getSingleData,
  deleteData,
} from "../../Utils/CallApiUtils";
import {
  createColumnDef,
  createColumnDefButton,
} from "../../Components/Common/AgGridColumnDef";
import { convertDateFormatWithOutTime } from "../../Utils/commonFunction";

export default function Blog() {
  const [blogData, setBlogData] = useState([]);
  const [editblogData, setEditblogData] = useState(null);
  const { setIsLoading } = useLoadingContext();
  const [showBlogModal, setShowBlogModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getBlog();
  }, []);

  const getBlog = async () => {
    await getAllData("Blog/AllBlog", setIsLoading, setBlogData);
    setSearchValue("");
  };

  const getSingleBlog = async (id) => {
    const Result = await getSingleData(
      `Blog/SingleBlog?BlogID=${id}`,
      setEditblogData,
      setIsLoading
    );
    if (Result) {
      setShowBlogModal(true);
    }
  };

  // Delete Area
  const DeleteProductCategory = async (id) => {
    ;
    await deleteData(`Blog/DeleteBlog?BlogID=${id}`, setIsLoading, getBlog);
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await getAllData(
        `Blog/SearchBlog?blogTitle=${searchValue}`,
        setIsLoading,
        setBlogData
      );
    }
  };

  const columnDefs = [
    {
      headerName: "Blog Details", // Header spanning across multiple columns
      cellRenderer: function (params) {
        const stripHtmlTags = (input) => {
          const doc = new DOMParser().parseFromString(input, "text/html");
          return doc.body.textContent || "";
        };

        const blogDetails = stripHtmlTags(params.data.blogDetails || "N/A");

        return (
          <div className="text-start Inter-font">
            <strong>Blog Title:</strong> {params.data.blogTitleName || "N/A"}{" "}
            <hr />
            <strong>Blog Date:</strong>{" "}
            {convertDateFormatWithOutTime(params.data.blogDate || "N/A")} <hr />
            <strong>Blog Details:</strong> {blogDetails}{" "}
          </div>
        );
      },
      flex: 1,
      minWidth: 400,
      autoHeight: true,
    },
    createColumnDef("IsActive", "isActive", 150),
    createColumnDef("Home Page", "isHome", 150),
    {
      headerName: "Image",
      flex: 1,
      minWidth: 450,
      field: "blogImagePath",
      autoHeight: true,
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum ",
      cellRenderer: ({ value }) => (
        <div className="p-2.5">
          <Image
            style={{ width: "50%", height: "50%" }}
            src={UserSetting.imgURL + value}
          ></Image>
        </div>
      ),
    },
    createColumnDefButton("Edit", "blogID", (value) => (
      <button onClick={() => getSingleBlog(value)}>
        <Edit />
      </button>
    )),
    createColumnDefButton("Delete", "blogID", (value) => (
      <button onClick={() => DeleteProductCategory(value)}>
        <Delete />
      </button>
    )),
  ];

  const HandlModelClose = () => {
    setShowBlogModal(false);
    setEditblogData(null);
  };
  return (
    <>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <span>Blog</span>
        <div className="d-flex align-items-center">
          <Form.Group controlId="formHorizontalEmail" className="mb-2">
            <Col className="d-flex">
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="productName"
                  placeholder="Search Blog Enter Titlename"
                  value={searchValue}
                  onChange={handleInputChange}
                  onKeyPress={handleSearch}
                  required
                  className="form-control"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter productName.
                </Form.Control.Feedback>
              </InputGroup>
              <Button variant="outline-secondary" onClick={getBlog}>
                <i className="fa fa-refresh"></i>
              </Button>
            </Col>
          </Form.Group>
          <Button
            style={{ height: "50px" }}
            className="btn-tapti ml-2"
            onClick={() => setShowBlogModal(true)}
          >
            Add Product
          </Button>
        </div>
      </Card.Header>
      <Card.Body className="withBorder">
        {blogData.length > 0 && (
          <AgGridComponent
            rowData={blogData}
            columnDefs={columnDefs}
            paginationPageSize={15}
          />
        )}
      </Card.Body>

      <GlobalModal
        title="New Blog"
        bodyComponent={
          <BlogComponent
            onClose={HandlModelClose}
            returnID={getBlog}
            editedblogData={editblogData}
            clearData={showBlogModal}
          />
        }
        isOpen={showBlogModal}
        onClose={HandlModelClose}
      />
    </>
  );
}
