import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import Loader from "../../Components/Common/Loader ";
import { convertDateFormat } from "../../Utils/commonFunction";
import { Edit, Delete } from "../../Components/Common/EditDelete";

export default function Inquiry() {
  const [ContactData, setContactData] = useState([]);
  const [editContactData, setEditContactData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  useEffect(() => {
    getContact();
  }, []);

  // Fetch all Areas
  const getContact = () => {
    setIsLoading(true);
    api
      .get("ContactUs/AllContact")
      .then((result) => {
        setContactData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getseenunseen = (id) => {
    
    api
      .patch(`ContactUs/EditContactSeenStatus?ContactID=${id}`)
      .then((result) => {
        toast.success(result.data.message);
        getContact();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const DeleteContact = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`ContactUs/DeleteContact?ContactID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getContact();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };
  const columnDefs = [
    {
      headerName: "ContactInfo", // Header spanning across multiple columns
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>ContactName:</strong> {params.data.name || "N/A"} <hr />
            <strong>EmailAddress:</strong> {params.data.emailAddress || "N/A"} <hr />
            <strong>MobileNo:</strong> {params.data.mobileNo || "N/A"} <hr />
            <strong>Country:</strong> {params.data.countryCode || "N/A"} <hr />
            <strong>Message:</strong> {params.data.details || "N/A"} <hr />
          </div>
        );
      },
      flex: 1,
      minWidth: 400,
      autoHeight: true,
    },
    {
      headerName: "Seen Status ",
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            {params.data.seen ? "Seen" : "Unseen"}
            {params.data.seen && (
              <>
                <hr /> <strong>Seen Date:</strong>
              </>
            )}
            {params.data.seen ? convertDateFormat(params.data.seenDate) : ""}
          </div>
        );
      },
      flex: 1,
      minWidth: 300,
      autoHeight: true,
    },
    {
      headerName: "Seen",
      width: 90,
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ data }) => (
        <lord-icon
          src="https://cdn.lordicon.com/ccrgnftl.json"
          trigger="hover"
          style={{ width: "50px", height: "50px" }}
          onClick={() => getseenunseen(data.contactID)}
          state={data.seen ? undefined : "hover-cross"}
        />
      ),
      pinned: "right",
    },

    {
      headerName: "Delete",
      width: 90,
      field: "contactID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button onClick={() => DeleteContact(value)}>
          <Delete />
        </button>
      ),
      pinned: "right",
    },
  ];

  const HandlModelClose = () => {
    setShowContactModal(false);
    setEditContactData(null);
  };

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card.Header className="d-flex justify-content-between">
        <span>ContactDetail</span>
      </Card.Header>
      <Card.Body className="withBorder">
        <AgGridComponent
          rowData={ContactData}
          columnDefs={columnDefs}
          paginationPageSize={7}
        />
      </Card.Body>
    </>
  );
}
