import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, InputGroup, Button, Image } from "react-bootstrap";
import api from "../../Utils/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserSetting from "../../UserSettings.json";
import MasterMenuDDComponent from "../MasterMenu/MasterMenuDDComponent";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import GlobalDDComponent from "../../Components/Common/GlobalDDComponent";
import MasterMenuComponent from "../MasterMenu/MasterMenuComponent";

const emptySubMenu = {
  SubMenu_ID: 0,
  MasterMenuID: 0,
  SubMenuName: "",
  MetaKeywords: "",
  MetaDescription: "",
  Title: "",
  IsHomePage: true,
  DisplayPriority: 0,
};

export default function SubMenuComponent({
  SubMenuID,
  returnID,
  editedSubMenuData,
  clearData,
  onClose,
}) {
  const [refreshKey, setRefreshKey] = useState(0); // Key for refreshing the dropdown
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptySubMenu);
  const SubMenuFormRef = useRef(null);
  const imageInputRef = useRef(null);
  const homePageImageInput = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedHomePageImage, setSelectedHomePageImage] = useState(null);
  const [homePageImagePreview, setHomePageImagePreview] = useState(null);
  const [ddValidationError, setDDValidationError] = useState(false);
  const [details, setDetails] = useState("");

  useEffect(() => {
    if (editedSubMenuData) {
      
      setFormData({
        SubMenu_ID: editedSubMenuData.subMenuID,
        SubMenuName: editedSubMenuData.subMenuName,
        MetaKeywords: editedSubMenuData.metaKeywords,
        MetaDescription: editedSubMenuData.metaDescription,
        Title: editedSubMenuData.title,
        IsHomePage: editedSubMenuData.isHomePage,
        MasterMenuID: editedSubMenuData.masterMenuID,
        DisplayPriority: editedSubMenuData.displayPriority,
      });
      if (editedSubMenuData.detail) {
        setDetails(editedSubMenuData.detail);
      }
  
      setImagePreview(UserSetting.imgURL + editedSubMenuData.imagePath);
      setHomePageImagePreview(
        UserSetting.imgURL + editedSubMenuData.imagePathForHomePage
      );
    }
  }, [editedSubMenuData]);

  useEffect(() => {
    if (clearData == false) {
      reset();
    }
  }, [clearData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
  };

  // Insert & Update Data
  const insertSubMenuMaster = (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setDDValidationError(formData.MasterMenuID === 0);
      event.stopPropagation();
      return;
    } else {
      const form_Data = new FormData();
      form_Data.append("SubMenuID", formData?.SubMenu_ID || 0);
      form_Data.append("SubMenuName", formData.SubMenuName);
      form_Data.append("MetaKeywords", formData.MetaKeywords);
      form_Data.append("MetaDescription", formData.MetaDescription);
      form_Data.append("Title", formData.Title);
      form_Data.append("Detail", details);
      form_Data.append("IsHomePage", formData.IsHomePage);
      form_Data.append("MasterMenuID", formData.MasterMenuID);
      form_Data.append("DisplayPriority", formData.DisplayPriority);
      form_Data.append("image", selectedImage);
      form_Data.append("imageForHomePage", selectedHomePageImage);

      const endpoint =
        formData.SubMenu_ID === 0
          ? "SubMenu/InsertSubMenu"
          : "SubMenu/EditSubMenu";

      api[formData.SubMenu_ID === 0 ? "post" : "patch"](endpoint, form_Data)
        .then((result) => {
          if (result.status === 200) {
            toast.success(result.data.message);
            if (SubMenuID) {
              SubMenuID(result.data.data);
            }
            if (returnID) {
              returnID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
          setRefreshKey(refreshKey + 1);
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred");
        })
        .finally(() => {
          reset();
          onClose();
        });
    }
  };

  const reset = () => {
    setSelectedImage(null);
    setSelectedHomePageImage(null);
    setHomePageImagePreview(null);
    setImagePreview(null);
    if (imageInputRef.current) {
      imageInputRef.current.value = null;
    }
    if (homePageImageInput.current) {
      homePageImageInput.current.value = null;
    }
    setFormData(emptySubMenu);
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
    // Preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleHomePageImageChange = (event) => {
    setSelectedHomePageImage(event.target.files[0]);
    // Preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setHomePageImagePreview(reader.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        // onSubmit={(event) => insertSubMenuMaster(event, { resetForm })}
        onSubmit={(e) => {
          if (e) {
            e.preventDefault();
          }

          if (SubMenuFormRef.current && e) {
            // Check for the formName to call insertCity
            if (e.target.name === "SubMenuForm") {
              insertSubMenuMaster(e);
            }
          }
        }}
        ref={SubMenuFormRef}
        name="SubMenuForm"
      >
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="MasterMenu_name">
              <Form.Label>SubMenu Name:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="SubMenuName"
                  placeholder="Enter SubMenu Name"
                  value={formData.SubMenuName}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter SubMenu Name.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <GlobalDDComponent
          ColSize={8}
          formLabel="Master Menu"
          apiEndpoint="MasterMenu/AllMasterMenus"
          valueOfDD="masterMenuID"
          labelOfDD="masterMenuName"
          onSelectOption={(masterMenuID) => {
            setFormData((prevData) => ({
              ...prevData,
              MasterMenuID: masterMenuID,
            }));
            setDDValidationError(false);
          }}
          selectedOptionOfComponent={formData.MasterMenuID}
          ValidationError={ddValidationError}
          offcanvasTitle="Add MasterMenu"
          offcanvasBodyComponent={<MasterMenuComponent />}
        />
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="MasterMenu_name">
              <Form.Label>Title:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="Title"
                  placeholder="Enter Title"
                  value={formData.Title}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Title.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="HeaderField">
              <Form.Label>Detail:</Form.Label>
              <InputGroup className="mb-3">
                <div style={{ height: "400px", width: "100%" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={details}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDetails(data);
                    }}
                  />
                </div>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Image:</Form.Label>
              <Form.Control
                type="file"
                onChange={handleImageChange}
                ref={imageInputRef}
              />
            </Form.Group>
          </Col>
          {imagePreview && (
            <Col xs={4} md={4}>
              <Image src={imagePreview} thumbnail />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="MasterMenu_name">
              <Form.Label>Meta Keywords :</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="MetaKeywords"
                  placeholder="Enter Meta Keywords "
                  value={formData.MetaKeywords}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter MetaKeywords.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="MasterMenu_name">
              <Form.Label>Meta Description:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="MetaDescription"
                  placeholder="Enter MetaDescription "
                  value={formData.MetaDescription}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter MetaDescription.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="SubMenu_name">
              <Form.Label>DisplayPriority:</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="fas fa-building"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="DisplayPriority"
                  placeholder="Enter SubMenu Name"
                  value={formData.DisplayPriority}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter SubMenu Name.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Image ForHomePage:</Form.Label>
              <Form.Control
                type="file"
                onChange={handleHomePageImageChange}
                ref={homePageImageInput}
              />
            </Form.Group>
          </Col>
          {homePageImagePreview && (
            <Col xs={4} md={4}>
              <Image src={homePageImagePreview} thumbnail />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={4} sm={4} lg={4}>
            <Form.Group controlId="isActive">
              <Form.Label></Form.Label>
              <Form.Check
                type="checkbox"
                label="Is HomePage"
                name="IsHomePage"
                checked={formData.IsHomePage}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="  margin_top10 items-end flex justify-end">
          <Button
            type="submit"
            variant="primary"
            className="mb-20px btn-tapti "
          >
            {formData?.SubMenu_ID > 0 ? "Update" : "Save"}
          </Button>
        </div>
      </Form>
    </>
  );
}
