import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import UserSetting from "../../UserSettings.json";
import Loader from "../../Components/Common/Loader ";
import SubMenuComponent from "../SubMenu/SubMenuComponent";
import { convertDateFormat } from "../../Utils/commonFunction";
import { Edit ,Delete } from "../../Components/Common/EditDelete";
import DynamicTable from "../../Components/Common/DynamicTable";


export default function SubMenu() {
  const [SubMenuData, setSubMenuData] = useState([]);
  const [editSubMenuData, setEditSubMenuData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSubMenuModal, setShowSubMenuModal] = useState(false);

  useEffect(() => {
    getSubMenu();
  }, []);

  // Fetch all Areas
  const getSubMenu = async () => {
    ;
    setIsLoading(true);
   await api
      .get("SubMenu/AllSubMenus")
      .then((result) => {
        setSubMenuData(result.data.data);
        console.log(result.data.data)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSingleCompony = async (id) => {
    ;
   await api
      .get(`SubMenu/SingleSubMenu?SubMenuID=${id}`)
      .then((result) => {
        const SubMenuData = result.data.data;
        setEditSubMenuData(SubMenuData);
        setShowSubMenuModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const DeleteSubMenu =  (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
         api
          .patch(`SubMenu/DeleteSubMenu?SubMenuID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getSubMenu();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const columnDefs = [
    {
      headerName: "Page Details", // Header spanning across multiple columns
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>SubMenu Name:</strong> {params.data.subMenuName || "N/A"} <hr />
            <strong>Master Name:</strong> {params.data.masterMenuName || "N/A"} <hr />
            <strong>Title:</strong> {params.data.title || "N/A"} <hr />
            <strong>Details:</strong>{" "}
            <div
              style={{ whiteSpace: "normal", wordWrap: "break-word" }}
              dangerouslySetInnerHTML={{ __html: params.data.detail }}
            />
          </div>
        );
      },
      flex: 1,
      minWidth: 400,
      autoHeight: true,
    },
    {
      headerName: "Meta Details",
      field: "socialMedia",
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>Meta Keywords:</strong> {params.data.metaKeywords || "N/A"}{" "}
            <hr />
            <p style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
              {" "}
              <strong>Meta Description:</strong>{" "}
              {params.data.metaDescription || "N/A"}{" "}
            </p>
          </div>
        );
      },
      flex: 1,
      minWidth: 300,
    },
    {
      headerName: "Image",
      flex: 1,
      minWidth: 150,
      field: "imagePath",
      autoHeight: true,
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum ",
      cellRenderer: ({ value }) => (
        <div className="p-2.5">
          <Image style={{width:"50%" ,height:"50%"}} src={UserSetting.imgURL + value}></Image>
        </div>
      ),
    },{
      headerName: "ImagePathForHomePage",
      flex: 1,
      minWidth: 150,
      field: "imagePathForHomePage",
      autoHeight: true,
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum ",
      cellRenderer: ({ value }) => (
        <div className="p-2.5">
          <Image style={{width:"50%" ,height:"50%"}} src={UserSetting.imgURL + value}></Image>
        </div>
      ),
    },
        {
          headerName: "DisplayPriority",
          field: "displayPriority",
          headerClass: "widthTableColum",
          sortable: true,
          filter: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          wrapText: true,
          autoHeight: true,
          flex: 1,
          minWidth: 20,
        },
    {
      headerName: "Edit",
      width: 70,
      field: "subMenuID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button  onClick={() => getSingleCompony(value)}><Edit/></button>
      ),
      pinned: "right",
    },
    {
      headerName: "Delete",
      width: 90,
      field: "subMenuID",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button  onClick={() => DeleteSubMenu(value)}><Delete/></button>
      ),
      pinned: "right",
    },
  ];

  const HandlModelClose = () => {
    setShowSubMenuModal(false);
    setEditSubMenuData(null);
  };

  
  return (
    <>
    
    {isLoading && <Loader />}
    <ToastContainer />
    <Card.Header className="d-flex justify-content-between">
      <span>SubMenu</span>
      <Button className="btn-tapti" onClick={() => setShowSubMenuModal(true)}>
        Add SubMenu
      </Button>{" "}
    </Card.Header>
    <Card.Body className="withBorder">
      <AgGridComponent
        rowData={SubMenuData}
        columnDefs={columnDefs}
        paginationPageSize={15}
      />
    </Card.Body>

    <GlobalModal
      title="New SubMenu"
      bodyComponent={
        <SubMenuComponent
          onClose={HandlModelClose}
          SubMenuID={getSubMenu}
          editedSubMenuData={editSubMenuData}
          clearData = {showSubMenuModal}
        />
      }
      isOpen={showSubMenuModal}
      onClose={HandlModelClose}
    />
  </>  
  )
}
