import React, { useState, useEffect, useRef } from 'react';
import { Form, Col, Row, InputGroup, Button } from 'react-bootstrap';
import api from '../../Utils/api'; // Adjust the import path as needed
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const emptyCarousel = {
  CarouselID: 0,
  CarouselWriterName: '',
  CarouselDetails: '',
  CarouselEmailID: '',
};

export default function CarouselComponent({ carouselID, editedCarouselData, clearData, onClose }) {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(emptyCarousel);
  const carouselFormRef = useRef(null);

  useEffect(() => {
    if (clearData === false) {
      setFormData(emptyCarousel);
    }
  }, [clearData]);

  useEffect(() => {
    if (editedCarouselData) {
      setFormData({
        CarouselID: editedCarouselData.carouselID,
        CarouselWriterName: editedCarouselData.carouselWriterName,
        CarouselDetails: editedCarouselData.carouselDetails,
        CarouselEmailID: editedCarouselData.carouselEmailID,
      });
    }
  }, [editedCarouselData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    setValidated(true);
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    } else {
      const data = {
        carouselID: formData.CarouselID,
        carouselWriterName: formData.CarouselWriterName,
        carouselDetails: formData.CarouselDetails,
        carouselEmailID: formData.CarouselEmailID,
      };

      const endpoint = formData.CarouselID === 0 ? 'Carousel/InsertCarousel' : 'Carousel/EditCarousel';

      api[formData.CarouselID === 0 ? 'post' : 'patch'](endpoint, data)
        .then((result) => {
          if (result.status === 200) {
            setValidated(false);
            toast.success(result.data.message);
            if (carouselID) {
              carouselID(result.data.data);
            }
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error('An error occurred');
        })
        .finally(() => {
          setFormData(emptyCarousel);
          onClose();
        });
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit} ref={carouselFormRef}>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="CarouselWriterName">
              <Form.Label>Writer Name:</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  name="CarouselWriterName"
                  placeholder="Enter Writer Name"
                  value={formData.CarouselWriterName}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a writer name.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="CarouselDetails">
              <Form.Label>Details:</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="CarouselDetails"
                  placeholder="Enter Details"
                  value={formData.CarouselDetails}
                  onChange={handleInputChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter details.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} lg={8}>
            <Form.Group controlId="CarouselEmailID">
              <Form.Label>Email ID:</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="email"
                  name="CarouselEmailID"
                  placeholder="Enter Email ID"
                  value={formData.CarouselEmailID}
                  onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email ID.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-3 text-end">
          <Button type="submit" variant="primary">
            {formData.CarouselID > 0 ? 'Update' : 'Save'}
          </Button>
        </div>
      </Form>
    </>
  );
}
