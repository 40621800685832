// import React, { useState, useEffect, useRef } from "react";
// import { toast } from "react-toastify";
// import { useLoadingContext } from "../../Loading/LoadingContext";
// import AgGridComponent from "../../Components/Common/AgGridComponent";
// import ProductComponent from "../Product/ProductComponent";
// import { Edit, Delete } from "../../Components/Common/EditDelete";
// import {
//   Col,
//   Form,
//   InputGroup,
//   Button,
//   Card,
//   Image,
//   Modal,
// } from "react-bootstrap";
// import {
//   getAllData,
//   getSingleData,
//   deleteData,
// } from "../../Utils/CallApiUtils";
// import { createColumnDefButton } from "../../Components/Common/AgGridColumnDef";
// import UserSetting from "../../UserSettings.json";
// import GlobalModal from "../../Components/Common/GlobalModal";
// import Swal from "sweetalert2";
// import api from "../../Utils/api";
// import Loader from "../../Components/Common/Loader ";
// export default function Product() {
//   const [ProductData, setProductData] = useState([]);
//   const [editProductData, setEditProductData] = useState(null);
//   const { setIsLoading, isLoading } = useLoadingContext();
//   const [showProductModal, setShowProductModal] = useState(false);
//   const [searchValue, setSearchValue] = useState("");
//   const [showProductImageModal, setShowProductImageModal] = useState(false);
//   const [allImagesForModel, setAllImagesForModel] = useState([]);
//   const [productIDForModel, setProductIDForModel] = useState(0);
//   const [SelectImage, setSelectImage] = useState([]);
//   const ImageAddRefForModel = useRef();

//   useEffect(() => {
//     getProduct();
//   }, []);

//   const getProduct = async () => {
//     await getAllData(
//       "Product/AllProductsDiamque",
//       setIsLoading,
//       setProductData
//     );
//     setSearchValue("");
//   };

//   const handleInputChange = (e) => {
//     setSearchValue(e.target.value);
//   };

//   const handleSearch = async (e) => {
//     if (e.key === "Enter") {
//       e.preventDefault();
//       await getAllData(
//         `Product/SearchProduct?productName=${searchValue}`,
//         setIsLoading,
//         setProductData
//       );
//     }
//   };

//   const getSingleCompony = async (id) => {
//     const Result = await getSingleData(
//       `Product/SingleProduct?ProductID=${id}`,
//       setEditProductData,
//       setIsLoading
//     );
//     if (Result) {
//       setShowProductModal(true);
//     }
//   };

//   // Delete Area
//   const DeleteProduct = async (id) => {
//     await deleteData(
//       `Product/DeleteProductForMultiImage?ProductID=${id}`,
//       setIsLoading,
//       getProduct
//     );
//   };

//   const getAllImage = async (id) => {
//     const Result = await getSingleData(
//       `Product/GetImagesByProductId?productId=${id}`,
//       setAllImagesForModel,
//       setIsLoading
//     );
//     if (Result) {
//       setShowProductImageModal(true);
//     }
//   };

//   const handleImageDelete = async (imageForAllID) => {
//     const result = await Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to delete this image!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     });

//     if (result.isConfirmed) {
//       setIsLoading(true);
//       try {
//         const response = await api.patch(
//           `Product/DeleteImageByImageFromID?imageForAllID=${imageForAllID}`
//         );
//         if (response.status === 200) {
//           Swal.fire({
//             icon: "success",
//             title: "Your record has been deleted.",
//             showConfirmButton: false,
//             timer: 1500,
//           });
//           getAllImage(productIDForModel);
//         }
//       } catch (error) {
//         console.error(error);
//         Swal.fire("Error", "An error occurred.", "error");
//       } finally {
//         setIsLoading(false);
//       }
//     }
//   };

//   const columnDefs = [
//     {
//       headerName: "Product Details",
//       cellRenderer: function (params) {
//         return (
//           <div className="text-start Inter-font">
//             <strong>Product Name:</strong> {params.data.productName || "N/A"}{" "}
//             <hr />
//             <strong>Category:</strong>{" "}
//             {params.data.productCategoryName || "N/A"} <hr />
//             <strong>Variant:</strong> {params.data.variant || "N/A"} <hr />
//             <strong>Instock:</strong> {params.data.instock || "N/A"} <hr />
//             <strong>Product Flag:</strong> {params.data.productFlag || "N/A"}{" "}
//             <hr />
//             <strong>Product Active:</strong>{" "}
//             {params.data.isActive == true ? "Yes" : "No"} <hr />
//             <strong>Home Page:</strong>{" "}
//             {params.data.isHomePage == true ? "Yes" : "No"} <hr />
//           </div>
//         );
//       },
//       flex: 1,
//       minWidth: 600,
//       autoHeight: true,
//     },
//     {
//       headerName: "Product Details",
//       cellRenderer: function (params) {
//         return (
//           <div className="text-start Inter-font">
//             <strong>Details:</strong>{" "}
//             <div
//               style={{ whiteSpace: "normal", wordWrap: "break-word" }}
//               dangerouslySetInnerHTML={{ __html: params.data.details }}
//             />
//             <hr />
//             <strong>Descrption:</strong>{" "}
//             <div
//               style={{ whiteSpace: "normal", wordWrap: "break-word" }}
//               dangerouslySetInnerHTML={{ __html: params.data.description }}
//             />
//           </div>
//         );
//       },
//       flex: 1,
//       minWidth: 600,
//       autoHeight: true,
//     },
//     {
//       headerName: "Images",
//       flex: 1,
//       minWidth: 150,
//       cellRenderer: (params) => (
//         <Button
//           variant="primary"
//           onClick={async () => {
//             try {
//               await getAllImage(params.data.productID);
//               setProductIDForModel(params.data.productID);
//               setShowProductImageModal(true);
//             } catch (error) {
//               console.error("Error fetching images:", error);
//               toast.error("Failed to load images.");
//             }
//           }}
//         >
//           View Images
//         </Button>
//       ),
//       cellStyle: {
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         textAlign: "center",
//       },
//       headerClass: "center-text widthTableColum",
//     },
//     createColumnDefButton("Edit", "productID", (value) => (
//       <button onClick={() => getSingleCompony(value)}>
//         <Edit />
//       </button>
//     )),
//     createColumnDefButton("Delete", "productID", (value) => (
//       <button onClick={() => DeleteProduct(value)}>
//         <Delete />
//       </button>
//     )),
//   ];

//   const HandlModelClose = () => {
//     setShowProductModal(false);
//     setEditProductData(null);
//   };

//   const handleImageModalClose = () => {
//     setShowProductImageModal(false);
//     setProductIDForModel(0);
//   };

//   const handleImageChange = (event) => {
//     const files = Array.from(event.target.files);
//     setSelectImage(files);
//   };

//   const InsertImageInProduct = () => {
//     if (SelectImage.length <= 0) {
//       toast.error("Please select images.");
//       return;
//     }
//     if (productIDForModel === 0) {
//       toast.error("Please select product first.");
//       return;
//     }

//     const form_Data = new FormData();
//     form_Data.append("ProductID", productIDForModel || 0);
//     SelectImage.forEach((image, index) => {
//       form_Data.append(`image${index}`, image);
//     });

//     api
//       .post(`Product/InsertNewImageInProduct`, form_Data)
//       .then((response) => {
//         setSelectImage([]);
//         getAllImage(productIDForModel);
//         ImageAddRefForModel.current.value = null;
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const handlePriorityChange = (index, newValue) => {
//     const updatedImages = [...allImagesForModel];
//     updatedImages[index].displayPriority = newValue;
//     setAllImagesForModel(updatedImages);
//   };

//   const UpadateDisplayPriority = async (imageForAllID, displayPriority) => {
//     setIsLoading(true);
//     const FinaldisplayPriority = displayPriority == "" ? 0 : displayPriority;
//     api
//       .get(
//         `Product/UpadateDisplayPriorityOfProductImages?imageForAllID=${imageForAllID}&DisplayPriority=${FinaldisplayPriority}`
//       )
//       .then((response) => {
//         if (response.data.statusCode === 200) {
//           toast.success(response.data.message);
//         } else {
//           toast.error(response.data.message);
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//         toast.error("Failed to update display priority.");
//       })
//       .finally(() => {
//         setIsLoading(false);
//         getAllImage(productIDForModel);
//       });
//   };

//   return (
//     <>
//       <Card.Header className="d-flex justify-content-between align-items-center">
//         <span>Product</span>
//         <div className="d-flex align-items-center">
//           <Form.Group controlId="formHorizontalEmail" className="mb-2">
//             <Col className="d-flex">
//               <InputGroup>
//                 <InputGroup.Text id="basic-addon1">
//                   <i className="fa fa-search"></i>
//                 </InputGroup.Text>
//                 <Form.Control
//                   type="text"
//                   name="productName"
//                   placeholder="Search Product"
//                   value={searchValue}
//                   onChange={handleInputChange}
//                   onKeyPress={handleSearch}
//                   required
//                   className="form-control"
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   Please Enter productName.
//                 </Form.Control.Feedback>
//               </InputGroup>
//               <Button variant="outline-secondary" onClick={getProduct}>
//                 <i className="fa fa-refresh"></i>
//               </Button>
//             </Col>
//           </Form.Group>
//           <Button
//             style={{ height: "50px" }}
//             className="btn-tapti ml-2"
//             onClick={() => setShowProductModal(true)}
//           >
//             Add Product
//           </Button>
//         </div>
//       </Card.Header>

//       <Card.Body className="withBorder">
//         {ProductData.length > 0 && (
//           <AgGridComponent
//             rowData={ProductData}
//             columnDefs={columnDefs}
//             paginationPageSize={15}
//           />
//         )}
//       </Card.Body>

//       <GlobalModal
//         title="New Product"
//         bodyComponent={
//           <ProductComponent
//             onClose={HandlModelClose}
//             returnID={getProduct}
//             editedProductData={editProductData}
//             clearData={showProductModal}
//           />
//         }
//         isOpen={showProductModal}
//         onClose={HandlModelClose}
//       />

//       <Modal
//         show={showProductImageModal}
//         onHide={handleImageModalClose}
//         dialogClassName="modal-90w"
//         size="xl"
//         centered
//         style={{ maxWidth: "100%", margin: "auto" }}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
//             Product Images
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body style={{ padding: "2rem" }}>
//           {isLoading && <Loader />}
//           <Form.Group controlId="formFile" className="mb-3">
//             <InputGroup className="mb-3">
//               <Form.Control
//                 type="file"
//                 ref={ImageAddRefForModel}
//                 multiple
//                 onChange={handleImageChange}
//               />
//               <InputGroup.Text id="basic-addon1" className="p-0">
//                 <Button onClick={InsertImageInProduct}>Add</Button>
//               </InputGroup.Text>
//             </InputGroup>
//           </Form.Group>
//           <hr />
//           <br />
//           {allImagesForModel && allImagesForModel.length > 0 ? (
//             <div
//               style={{
//                 display: "flex",
//                 flexWrap: "wrap",
//                 gap: "1rem",
//                 justifyContent: "center",
//               }}
//             >
//               {allImagesForModel.map((image, index) => (
//                 <div
//                   key={index}
//                   style={{
//                     position: "relative",
//                     border: "1px solid #ddd",
//                     borderRadius: "8px",
//                     overflow: "hidden",
//                     padding: "0.5rem",
//                     boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//                     backgroundColor: "#fff",
//                   }}
//                 >
//                   <Image
//                     style={{
//                       width: "237px",
//                       height: "237px",
//                       padding: "0.5rem",
//                       objectFit: "contained",
//                     }}
//                     src={UserSetting.imgURL + image.imagePath}
//                     alt={`Product Image ${index + 1}`}
//                   />

//                   <Form.Group controlId="DisplayPriority">
//                     <InputGroup>
//                       <Form.Control
//                         className="w-full"
//                         type="number"
//                         name="DisplayPriority"
//                         placeholder="Display Priority"
//                         value={
//                           image.displayPriority ? image.displayPriority : ""
//                         }
//                         onChange={(e) =>
//                           handlePriorityChange(index, e.target.value)
//                         }
//                       />
//                       <InputGroup.Text id="basic-addon1" className="p-0 m-0">
//                         <Button
//                           variant="success"
//                           onClick={() => {
//                             UpadateDisplayPriority(
//                               image.imageForAllID,
//                               image.displayPriority
//                             );
//                           }}
//                         >
//                           Add
//                         </Button>
//                       </InputGroup.Text>
//                       <InputGroup.Text id="basic-addon2" className="p-0 m-0">
//                         <Button
//                           variant="danger"
//                           onClick={() => handleImageDelete(image.imageForAllID)}
//                         >
//                           Delete
//                         </Button>
//                       </InputGroup.Text>
//                     </InputGroup>
//                   </Form.Group>
//                 </div>
//               ))}
//             </div>
//           ) : (
//             <p style={{ textAlign: "center" }}>No images available.</p>
//           )}
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// }

import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useLoadingContext } from "../../Loading/LoadingContext";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import ProductComponent from "../Product/ProductComponent";
import { Edit, Delete } from "../../Components/Common/EditDelete";
import {
  Col,
  Form,
  InputGroup,
  Button,
  Card,
  Image,
  Modal,
} from "react-bootstrap";
import {
  getAllData,
  getSingleData,
  deleteData,
} from "../../Utils/CallApiUtils";
import { createColumnDefButton } from "../../Components/Common/AgGridColumnDef";
import UserSetting from "../../UserSettings.json";
import GlobalModal from "../../Components/Common/GlobalModal";
import Swal from "sweetalert2";
import api from "../../Utils/api";
import Loader from "../../Components/Common/Loader ";
export default function Product() {
  const [ProductData, setProductData] = useState([]);
  const [editProductData, setEditProductData] = useState(null);
  const { setIsLoading, isLoading } = useLoadingContext();
  const [showProductModal, setShowProductModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showProductImageModal, setShowProductImageModal] = useState(false);
  const [allImagesForModel, setAllImagesForModel] = useState([]);
  const [productIDForModel, setProductIDForModel] = useState(0);
  const [SelectImage, setSelectImage] = useState([]);
  const ImageAddRefForModel = useRef();

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = async () => {
    await getAllData(
      "Product/AllProductsDiamque",
      setIsLoading,
      setProductData
    );
    setSearchValue("");
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await getAllData(
        `Product/SearchProduct?productName=${searchValue}`,
        setIsLoading,
        setProductData
      );
    }
  };

  const getSingleCompony = async (id) => {
    const Result = await getSingleData(
      `Product/SingleProduct?ProductID=${id}`,
      setEditProductData,
      setIsLoading
    );
    if (Result) {
      setShowProductModal(true);
    }
  };

  // Delete Area
  const DeleteProduct = async (id) => {
    await deleteData(
      `Product/DeleteProductForMultiImage?ProductID=${id}`,
      setIsLoading,
      getProduct
    );
  };

  const getAllImage = async (id) => {
    const Result = await getSingleData(
      `Product/GetImagesByProductId?productId=${id}`,
      setAllImagesForModel,
      setIsLoading
    );
    if (Result) {
      setShowProductImageModal(true);
    }
  };

  const handleImageDelete = async (imageForAllID) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this image!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      setIsLoading(true);
      try {
        const response = await api.patch(
          `Product/DeleteImageByImageFromID?imageForAllID=${imageForAllID}`
        );
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Your record has been deleted.",
            showConfirmButton: false,
            timer: 1500,
          });
          getAllImage(productIDForModel);
        }
      } catch (error) {
        console.error(error);
        Swal.fire("Error", "An error occurred.", "error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const columnDefs = [
    {
      headerName: "Product Details",
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>Product Name:</strong> {params.data.productName || "N/A"}{" "}
            <hr />
            <strong>Category:</strong>{" "}
            {params.data.productCategoryName || "N/A"} <hr />
            <strong>Variant:</strong> {params.data.variant || "N/A"} <hr />
            <strong>Instock:</strong> {params.data.instock || "N/A"} <hr />
            <strong>Product Flag:</strong> {params.data.productFlag || "N/A"}{" "}
            <hr />
            <strong>Product Active:</strong>{" "}
            {params.data.isActive == true ? "Yes" : "No"} <hr />
            <strong>Home Page:</strong>{" "}
            {params.data.isHomePage == true ? "Yes" : "No"} <hr />
          </div>
        );
      },
      flex: 1,
      minWidth: 600,
      autoHeight: true,
    },
    {
      headerName: "Product Details",
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>Details:</strong>{" "}
            <div
              style={{ whiteSpace: "normal", wordWrap: "break-word" }}
              dangerouslySetInnerHTML={{ __html: params.data.details }}
            />
            <hr />
            <strong>Descrption:</strong>{" "}
            <div
              style={{ whiteSpace: "normal", wordWrap: "break-word" }}
              dangerouslySetInnerHTML={{ __html: params.data.description }}
            />
          </div>
        );
      },
      flex: 1,
      minWidth: 600,
      autoHeight: true,
    },
    {
      headerName: "Images",
      flex: 1,
      minWidth: 150,
      cellRenderer: (params) => (
        <Button
          variant="primary"
          onClick={async () => {
            try {
              await getAllImage(params.data.productID);
              setProductIDForModel(params.data.productID);
              setShowProductImageModal(true);
            } catch (error) {
              console.error("Error fetching images:", error);
              toast.error("Failed to load images.");
            }
          }}
        >
          View Images
        </Button>
      ),
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      },
      headerClass: "center-text widthTableColum",
    },
    createColumnDefButton("Edit", "productID", (value) => (
      <button onClick={() => getSingleCompony(value)}>
        <Edit />
      </button>
    )),
    createColumnDefButton("Delete", "productID", (value) => (
      <button onClick={() => DeleteProduct(value)}>
        <Delete />
      </button>
    )),
  ];

  const HandlModelClose = () => {
    setShowProductModal(false);
    setEditProductData(null);
  };

  const handleImageModalClose = () => {
    setShowProductImageModal(false);
    setProductIDForModel(0);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectImage(files);
  };

  const InsertImageInProduct = () => {
    if (SelectImage.length <= 0) {
      toast.error("Please select images.");
      return;
    }
    if (productIDForModel === 0) {
      toast.error("Please select product first.");
      return;
    }

    const form_Data = new FormData();
    form_Data.append("ProductID", productIDForModel || 0);
    SelectImage.forEach((image, index) => {
      form_Data.append(`image${index}`, image);
    });

    api
      .post(`Product/InsertNewImageInProduct`, form_Data)
      .then((response) => {
        setSelectImage([]);
        getAllImage(productIDForModel);
        ImageAddRefForModel.current.value = null;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handlePriorityChange = (index, newValue) => {
  //   const updatedImages = [...allImagesForModel];
  //   updatedImages[index].displayPriority = newValue;
  //   setAllImagesForModel(updatedImages);
  // };

  // const UpadateDisplayPriority = async (imageForAllID, displayPriority) => {
  //   setIsLoading(true);
  //   const FinaldisplayPriority = displayPriority == "" ? 0 : displayPriority;
  //   api
  //     .get(
  //       `Product/UpadateDisplayPriorityOfProductImages?imageForAllID=${imageForAllID}&DisplayPriority=${FinaldisplayPriority}`
  //     )
  //     .then((response) => {
  //       if (response.data.statusCode === 200) {
  //         toast.success(response.data.message);
  //       } else {
  //         toast.error(response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       toast.error("Failed to update display priority.");
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //       getAllImage(productIDForModel);
  //     });
  // };

  const [displayPriorities, setDisplayPriorities] = useState([]);

  // Ensure displayPriorities is set when allImagesForModel is loaded
  useEffect(() => {
    if (allImagesForModel && allImagesForModel.length > 0) {
      const initialPriorities = allImagesForModel.map((img) => ({
        id: img.imageForAllID,
        priority: img.displayPriority || 0, // Default to 0 if displayPriority is undefined
      }));
      setDisplayPriorities(initialPriorities);
    }
  }, [allImagesForModel]);

  // Function to handle priority change for individual images
  const handlePriorityChange = (index, value) => {
    setDisplayPriorities((prevPriorities) =>
      prevPriorities.map((item, i) =>
        i === index ? { ...item, priority: value === "" ? 0 : value } : item
      )
    );
  };

  // Function to update display priority for all images at once
  const updateAllDisplayPriorities = async () => {
    setIsLoading(true);
    try {
      for (const img of displayPriorities) {
        await api.get(
          `Product/UpadateDisplayPriorityOfProductImages?imageForAllID=${img.id}&DisplayPriority=${img.priority}`
        );
      }
      toast.success("Display priorities updated successfully.");
      getAllImage(productIDForModel); // Refresh images
    } catch (error) {
      console.error(error);
      toast.error("Failed to update display priorities.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <span>Product</span>
        <div className="d-flex align-items-center">
          <Form.Group controlId="formHorizontalEmail" className="mb-2">
            <Col className="d-flex">
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  name="productName"
                  placeholder="Search Product"
                  value={searchValue}
                  onChange={handleInputChange}
                  onKeyPress={handleSearch}
                  required
                  className="form-control"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter productName.
                </Form.Control.Feedback>
              </InputGroup>
              <Button variant="outline-secondary" onClick={getProduct}>
                <i className="fa fa-refresh"></i>
              </Button>
            </Col>
          </Form.Group>
          <Button
            style={{ height: "50px" }}
            className="btn-tapti ml-2"
            onClick={() => setShowProductModal(true)}
          >
            Add Product
          </Button>
        </div>
      </Card.Header>
      <Card.Body className="withBorder">
        {ProductData.length > 0 && (
          <AgGridComponent
            rowData={ProductData}
            columnDefs={columnDefs}
            paginationPageSize={15}
          />
        )}
      </Card.Body>
      <GlobalModal
        title="New Product"
        bodyComponent={
          <ProductComponent
            onClose={HandlModelClose}
            returnID={getProduct}
            editedProductData={editProductData}
            clearData={showProductModal}
          />
        }
        isOpen={showProductModal}
        onClose={HandlModelClose}
      />
      <Modal
        show={showProductImageModal}
        onHide={handleImageModalClose}
        dialogClassName="modal-90w"
        size="xl"
        centered
        style={{ maxWidth: "100%", margin: "auto" }}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            Product Images
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "2rem" }}>
          {isLoading && <Loader />}
          <Form.Group controlId="formFile" className="mb-3">
            <InputGroup className="mb-3">
              <Form.Control
                type="file"
                ref={ImageAddRefForModel}
                multiple
                onChange={handleImageChange}
              />
              <InputGroup.Text id="basic-addon1" className="p-0">
                <Button onClick={InsertImageInProduct}>Add</Button>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <hr />
          <br />
          {allImagesForModel && allImagesForModel.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "1rem",
                justifyContent: "center",
              }}
            >
              {allImagesForModel.map((image, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    overflow: "hidden",
                    padding: "0.5rem",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    backgroundColor: "#fff",
                  }}
                >
                  <Image
                    style={{
                      width: "237px",
                      height: "237px",
                      padding: "0.5rem",
                      objectFit: "contain",
                    }}
                    src={UserSetting.imgURL + image.imagePath}
                    alt={`Product Image ${index + 1}`}
                  />
                  <div style={{ display: "flex" }} className="space-x-2">
                    <Form.Group controlId="DisplayPriority">
                      <InputGroup>
                        <Form.Control
                          className="w-full"
                          type="number"
                          name="DisplayPriority"
                          placeholder="Display Priority"
                          value={displayPriorities[index]?.priority || ""}
                          onChange={(e) =>
                            handlePriorityChange(index, e.target.value)
                          }
                        />
                      </InputGroup>
                    </Form.Group>

                    <div style={{ textAlign: "center"}}>
                      <Button
                        variant="danger"
                        onClick={() => handleImageDelete(image.imageForAllID)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p style={{ textAlign: "center" }}>No images available.</p>
          )}
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <Button variant="success" onClick={updateAllDisplayPriorities}>
              Update All Display Priorities
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      ;
    </>
  );
}
