import React, { useState, useEffect } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import UserSetting from "../../UserSettings.json";
import Loader from "../../Components/Common/Loader ";
import CMSComponent from "../CMS/CMSComponent";
import { convertDateFormat } from "../../Utils/commonFunction";
import { Edit ,Delete } from "../../Components/Common/EditDelete";
import { createColumnHtml } from "../../Components/Common/AgGridColumnDef";

export default function CMS() {
  const [CMSData, setCMSData] = useState([]);
  const [editCMSData, setEditCMSData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showCMSModal, setShowCMSModal] = useState(false);

  useEffect(() => {
    getCMS();
  }, []);

  // Fetch all Areas
  const getCMS = () => {
    ;
    setIsLoading(true);
    api
      .get("CMS/AllCMS")
      .then((result) => {
        setCMSData(result.data.data);
        console.log(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSingleCompony = (id) => {
    ;
    api
      .get(`CMS/SingleCMS?CMSID=${id}`)
      .then((result) => {
        const CMSData = result.data.data;
        setEditCMSData(CMSData);
        setShowCMSModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const DeleteCMS = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`CMS/DeleteCMS?CMSID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getCMS();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };

  const columnDefs = [
    {
      headerName: "Page Details", // Header spanning across multiple columns
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>Title:</strong> {params.data.title || "N/A"} <hr />
            <strong>Details:</strong>{" "}
            <div
              style={{ whiteSpace: "normal", wordWrap: "break-word" }}
              dangerouslySetInnerHTML={{ __html: params.data.details }}
            />
          </div>
        );
      },
      flex: 1,
      minWidth: 400,
      autoHeight: true,
    },

    {
      headerName: "Meta Details",
      field: "socialMedia",
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            <strong>Meta Keywords:</strong> {params.data.metakeywords || "N/A"}{" "}
            <hr />
            <p style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
              {" "}
              <strong>Meta Description:</strong>{" "}
              {params.data.metaDescription || "N/A"}{" "}
            </p>
          </div>
        );
      },
      flex: 1,
      minWidth: 300,
    },
    {
      headerName: "Date Details", // Header spanning across multiple columns
      cellRenderer: function (params) {
        return (
          <div className="text-start Inter-font">
            {params.data.createdDate && (
              <>
                <strong>Created Date:</strong>
                {convertDateFormat(params.data.createdDate)}
                <hr />
              </>
            )}
            {params.data.modifiedDate && (
              <>
                <strong>Last Modify Date: </strong>
                {convertDateFormat(params.data.modifiedDate)}
              </>
            )}
            <hr />
          </div>
        );
      },
      flex: 1,
      minWidth: 400,
      autoHeight: true,
    },
    {
      headerName: "Edit",
      width: 70,
      field: "cmsid",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button  onClick={() => getSingleCompony(value)}><Edit/></button>
      ),
      pinned: "right",
    },
    {
      headerName: "Delete",
      width: 90,
      field: "cmsid",
      cellStyle: { textAlign: "center" },
      headerClass: "center-text widthTableColum",
      cellRenderer: ({ value }) => (
        <button  onClick={() => DeleteCMS(value)}><Delete/></button>
      ),
      pinned: "right",
    },
  ];

  const HandlModelClose = () => {
    setShowCMSModal(false);
    setEditCMSData(null);
  };
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card.Header className="d-flex justify-content-between">
        <span>CMS</span>
        <Button className="btn-tapti" onClick={() => setShowCMSModal(true)}>
          Add CMS
        </Button>{" "}
      </Card.Header>
      <Card.Body className="withBorder">
        <AgGridComponent
          rowData={CMSData}
          columnDefs={columnDefs}
          paginationPageSize={4}
        />
      </Card.Body>

      <GlobalModal
        title="New CMS"
        bodyComponent={
          <CMSComponent
            onClose={HandlModelClose}
            CMSID={getCMS}
            editedCMSData={editCMSData}
            clearData = {showCMSModal}
          />
        }
        isOpen={showCMSModal}
        onClose={HandlModelClose}
      />
    </>
  );
}
